import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../components/Table';
import {Tailselect} from '../../../components/Tailselect';
import {numberWithCommas, unitsDisplayConversion, searchFind, customChartProperties} from '../../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {setInitialValues} from './upcomingModalSlice';
import {TabMenuTableWrapper} from '../../../components/TabMenuTableWrapper';
import {CustomTablePagination} from '../../../components/CustomTablePagination';

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

function UpcomingTable(props) {
  const dispatch = useDispatch();

  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });

  const [tableData, setTableData] = useState([]); // Partial data to render in table
  const [units, setUnits] = useState('km');
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({
    vehicles: [],
    tasks: [],
  });

  useEffect(() => {
    // Determine units based on customer units system
    if (unitsSystem == 'imperial') {
      setUnits('mi');
    } else {
      setUnits('km');
    }
  }, [unitsSystem]);

  useEffect(() => {
    filterTableRows(props.tableData);
  }, [props.tableData, props.reminderFilter, filters, searchText]);

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  async function filterTableRows(allTableData) {
    const tempTableData = allTableData.filter((rowData) => {
      return rowFilter(rowData, <div>{JSON.stringify(rowData)}</div>);
    });
    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }  

    setTableData(tempTableData);
  }

  function rowFilter(rowData, row) {
    const vehiclesInFilter = filters.vehicles.includes(rowData.vehicle) || filters.vehicles.length == 0;
    const reminderInFilter =
      (props.reminderFilter.includes('inspectionFailure') && rowData.inspectionFailure) ||
      (props.reminderFilter.includes('overdue') && rowData.overdue) ||
      (props.reminderFilter.includes('upcoming') && rowData.upcoming && !rowData.overdue) ||
      props.reminderFilter.length == 0;
    const taskInFilter = filters.tasks.includes(rowData.task) || filters.tasks.length == 0;
    const search = searchFind(row, searchText.toLowerCase().trim());

    return vehiclesInFilter && reminderInFilter && taskInFilter && search;
  }

  function generateTableData() {
    if (props.loading) {
      return (
        <TableBody id='table-body'>
          <TableRow>
            <TableCell colSpan={100}>
              <div className='m-2'>
                <div className='loader mx-auto'></div>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    let colorFlip = false;
    const paginatedData = 
      rowsPerPage > 0 ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData;

    return (
      <TableBody id='upcoming-table-body'>
        {paginatedData.map((rowData, index) => {
          if (!rowData) {
            return null;
          }
          colorFlip = !colorFlip;

          let leftBorderSx = '';
          if (rowData.inspectionFailure) {
            leftBorderSx = `7px solid ${customChartProperties.colorOrange}`;
          } else if (rowData.overdue) {
            leftBorderSx = `7px solid ${customChartProperties.colorDanger}`;
          } else if (rowData.upcoming) {
            leftBorderSx = `7px solid ${customChartProperties.colorWarning}`;
          }

          const row = (
            <TableRow
              key={index}
              sx={{
                backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : '',
                '& td:first-of-type': {borderLeft: leftBorderSx}
              }}
              onClick={() => {
                if ($(window).width() <= 767) {
                  dispatch(setInitialValues(rowData));
                  $('#shopview-upcoming-data-modal').modal('show');
                }
              }}
            >
              <TableCell className='d-none d-md-table-cell'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-toggle='modal'
                  data-target='#shopview-complete-task-modal'
                  onClick={() => {
                    dispatch(setInitialValues(rowData));
                  }}
                >
                  <FontAwesomeIcon icon='far fa-square' />
                </button>
              </TableCell>
              <TableCell className='text-secondary font-weight-bold' sx={{borderLeft: smallScreen ? leftBorderSx : ''}}>
                <div className='mb-2'>
                  <div >{rowData.vehicle}</div>
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <div className='text-secondary font-weight-bold'>{rowData.task}</div>
                </div>
                <div className='ml-2 text-xs upcoming-service-interval'>
                  <div className='text-secondary' dangerouslySetInnerHTML={{__html: rowData.intervalString}}></div>
                </div>
                <div className='upcoming-due-within-service'>
                  <div>
                    <strong>Due in:</strong>
                    <div className='ml-2 text-xs' dangerouslySetInnerHTML={{__html: rowData.dueString}} />
                  </div>
                </div>
              </TableCell>
              <TableCell className='d-none d-md-table-cell'>
                <div>
                  <div
                    className='text-secondary font-weight-light'
                    dangerouslySetInnerHTML={{__html: rowData.dueString}}
                  />
                </div>
              </TableCell>
              <TableCell className='d-none d-md-table-cell'>
                {rowData.noHistory && (
                  <div>
                    <div className='text-secondary font-weight-bold'>Task has no History</div>
                  </div>
                )}
                {!rowData.noHistory && (
                  <div>
                    <div className='text-secondary font-weight-bold d-inline'>Completed on: </div>
                    <div className='text-primary font-weight-normal d-inline'>{rowData.lastCompletedDate}</div>
                    <div className='text-secondary d-inline'> | {rowData.lastCompletedTimeDiff}</div>
                  </div>
                )}
                {!rowData.noHistory && (
                  <div>
                    <div className='text-secondary font-weight-bold d-inline'>Reviewed By: </div>
                    <div className='text-secondary d-inline'>{rowData.reviewedBy}</div>
                  </div>
                )}
                {!rowData.noHistory && (
                  <div>
                    <div className='text-secondary font-weight-bold d-inline'>Odometer When Complete: </div>
                    <div className='text-secondary d-inline'>
                      {rowData.lastCompletedOdometer === ''
                        ? ''
                        : `${numberWithCommas(unitsDisplayConversion(rowData.lastCompletedOdometer, units))} ${units}`}
                    </div>
                  </div>
                )}
                {!rowData.noHistory && (
                  <div>
                    <div className='text-secondary font-weight-bold d-inline'>Engine Hours When Complete: </div>
                    <div className='text-secondary d-inline'>
                      {rowData.lastCompletedEngineHours === ''
                        ? ''
                        : `${numberWithCommas(rowData.lastCompletedEngineHours, units)} hrs`}
                    </div>
                  </div>
                )}
              </TableCell>
            </TableRow>
          );
          return row;
        })}
      </TableBody>
    );
  }

  function generateTableHeaders() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='d-none d-md-table-cell' sx={{width:'5%'}}>
            Done
          </TableCell>
          <TableCell sx={{width:'20%'}}>
            <TableHeadFilterButtonTitleWrapper title='Equipment'>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#shopview-upcoming-vehicle-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.vehicles.length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='shopview-upcoming-vehicle-select'
                name='vehicles'
                multiple={true}
                search={true}
                value={filters.vehicles}
                options={props.filterOptions.vehicles}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell sx={{width:'20%'}}>
            <TableHeadFilterButtonTitleWrapper title='Service'>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#shopview-upcoming-task-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.tasks.length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='shopview-upcoming-task-select'
                name='tasks'
                multiple={true}
                search={true}
                value={filters.tasks}
                options={props.filterOptions.tasks}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell className='d-none d-md-table-cell' sx={{width:'20%'}}>
            Status
          </TableCell>
          <TableCell className='d-none d-md-table-cell' sx={{width:'35%'}}>
            Last Completed
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

    return (
      <React.Fragment>
      <div className='table-responsive'>
        <TabMenuTableWrapper
          table={
            <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
              {generateTableHeaders()}
              {generateTableData()}
            </Table>
          }
          pagination={
            <CustomTablePagination
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
            />
          }
        />
      </div>
      
      </React.Fragment>
    );
  }
  


export {UpcomingTable};
