import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties, isNumeric} from '../../../app/utils';
import {Tailselect} from '../../../components/Tailselect';
import {genServiceCostsGraphData} from '../dashboardDataProcessing';
import {KpiTooltip, ScorecardSelectHolder} from '../dashboardUtils';
import {Checkbox, FormControlLabel, CircularProgress} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(annotationPlugin);

function KpiServiceCosts(props) {
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const servicesData = useSelector((state) => {
    return state.dashboard.servicesData;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });

  const [serviceCostsData, setServiceCostsData] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [vehicleSnOptions, setVehicleSnOptions] = useState([]);
  const [displayedAxis, setDisplayedAxis] = useState('Total');
  const [stackedComparison, setStackedComparison] = useState(false);

  const [serviceCostsFilters, setServiceCostsFilters] = useState({
    serviceCostsVehicleSNs: [],
    serviceCostsVehicleType: [],
    serviceCostsVehicleCount: '10',
    serviceCostsVehicleSort: 'Desc',
  });

  useEffect(() => {
    if (servicesData != null) {
      generateData();
    }
  }, [servicesData, vehicleSNDict, serviceCostsFilters, displayedAxis, stackedComparison]);

  useEffect(() => {
    if (servicesData != null) {
      generateVehicleFilterOptions();
    }
  }, [servicesData]);

  useEffect(() => {
    if (props.pageStates?.displayedAxis) {
      setDisplayedAxis(props.pageStates?.displayedAxis);
    } else {
      setDisplayedAxis('Total');
    }
    if (props.pageStates?.stackedComparison) {
      setStackedComparison(props.pageStates?.stackedComparison);
    } else {
      setStackedComparison(false);
    }
    if (props?.pageFilters) {
      setServiceCostsFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
  }, [props.pageStates, props.pageFilters]);

  function generateData() {
    // Generate data
    const serviceCostsDataTemp = genServiceCostsGraphData(
      servicesData,
      displayedAxis,
      serviceCostsFilters,
      vehicleSNDict
    );
    if (Object.keys(servicesData.vehicleServices).length > 0) {
      setServiceCostsData(serviceCostsDataTemp);
    } else {
      setServiceCostsData(null);
    }

    const tempDatasets = [];
    serviceCostsDataTemp.datasets.forEach((setObj) => {
      const splitSetObj = setObj.id.split(".");
      if (setObj.id == displayedAxis && stackedComparison == false) {
        tempDatasets.push(setObj);
      } else if (splitSetObj[0] == displayedAxis && setObj.id.includes(".") && stackedComparison == true){
        tempDatasets.push(setObj);
      }
    });
    setDatasets(tempDatasets);
  }

  function generateVehicleFilterOptions() {
    // Get vehicle filter options
    const newVehicleSnOptions = Object.keys(servicesData.vehicleServices).map((vehicleSN) => {
      return {value: vehicleSN, text: servicesData.vehicleServices[vehicleSN].vehicleName};
    });
    setVehicleSnOptions(newVehicleSnOptions);
  }

  function handleVehiclesFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }

    const value = select.multiple ? selected : selected[0];
    const name = select.name;
    setServiceCostsFilters((values) => {
      return {...values, [name]: value};
    });
  }

  function serviceCostsGraph() {
    let graphTitle;
    let yAxisTitle;
    let xAxisTitle;

    switch (displayedAxis) {
      case 'PartsCosts':
        graphTitle = 'Total Parts Costs';
        yAxisTitle = 'Parts Costs ($)';
        xAxisTitle = 'Vehicle';
        break;
      case 'LaborCosts':
        graphTitle = 'Total Labor Costs';
        yAxisTitle = 'Labor Costs ($)';
        xAxisTitle = 'Vehicle';
        break;
      case 'LaborTime':
        graphTitle = 'Total Labor Time';
        yAxisTitle = 'Labor Time (hrs)';
        xAxisTitle = 'Vehicle';
        break;
      default:
        graphTitle = 'Total Service Costs (Parts + Labor)';
        yAxisTitle = 'Service Costs ($)';
        xAxisTitle = 'Vehicle';
    }

    return (
      <div style={{height: '60vh', minHeight: '500px'}}>
        {/* Chart */}
        <BarChart
          data={{...serviceCostsData, datasets: datasets}}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                text: graphTitle,
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              leftAxis: {
                stacked: stackedComparison,
                title: {
                  text: yAxisTitle,
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                type: 'linear',
                position: 'left',
              },
              x: {
                stacked: stackedComparison,
                ticks: {
                  autoSkip: serviceCostsData.labels.length > customChartProperties.autoSkipLimit,
                  maxRotation: 90,
                  minRotation: 90,
                  font: {
                    size: customChartProperties.xLabelFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                title: {
                  text: xAxisTitle,
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
            },
          }}
        />
      </div>
    );
  }

  // Check if loading
  if (loading) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset='kpiServiceCosts' />
        </div>
        <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>
          {!serviceCostsData ? (
            <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
          ) : (
            serviceCostsGraph()
          )}
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className='row d-flex mx-0 px-0 justify-content-center align-items-center'>
      {/* Tooltip */}
      <div className='col-6 flex-col ml-n1'>
        <KpiTooltip selectedSubset='kpiServiceCosts' />
      </div>
      <div className='col-6 px-0'>
        <ScorecardSelectHolder
          autofillPageName={'Service Costs'}
          pageFilters={serviceCostsFilters}
          pageStates={{
            displayedAxis: displayedAxis,
            stackedComparison: stackedComparison
          }}
        />
      </div>

      {!serviceCostsData ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>{serviceCostsGraph()}</div>
          <div className='col-lg-4 flex-col text-center px-0 align-items-center my-auto'>
            <div className='text-center align-items-center my-auto'>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setDisplayedAxis('Total');
                    }}
                    checked={displayedAxis == 'Total'}
                  />
                }
                label='Total Costs'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setDisplayedAxis('PartsCosts');
                    }}
                    checked={displayedAxis == 'PartsCosts'}
                  />
                }
                label='Parts Costs'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setDisplayedAxis('LaborCosts');
                    }}
                    checked={displayedAxis == 'LaborCosts'}
                  />
                }
                label='Labor Costs'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setDisplayedAxis('LaborTime');
                    }}
                    checked={displayedAxis == 'LaborTime'}
                  />
                }
                label='Labor Time'
              />
            </div>
            <div className='text-centeralign-items-center my-auto pb-3'>
              <Tailselect
                id='dashboard-vehicles-serviceCostsVehicleSNs-filter'
                name='serviceCostsVehicleSNs'
                multiple={true}
                search={true}
                value={serviceCostsFilters.serviceCostsVehicleSNs}
                options={vehicleSnOptions}
                onChange={handleVehiclesFilters}
                placeholder='Filter for Vehicles'
              />
              <Tailselect
                id='dashboard-vehicles-serviceCostsVehicleType-filter'
                name='serviceCostsVehicleType'
                multiple={true}
                search={true}
                value={serviceCostsFilters.serviceCostsVehicleType}
                options={filterOptions.vehicles.vehicleType}
                onChange={handleVehiclesFilters}
                placeholder='Filter for Vehicle Type'
              />
              <Tailselect
                id='dashboard-vehicles-serviceCostsVehicleCount-filter'
                name='serviceCostsVehicleCount'
                multiple={false}
                value={serviceCostsFilters.serviceCostsVehicleCount}
                options={filterOptions.vehicles.vehicleCount}
                onChange={handleVehiclesFilters}
                placeholder='Number Of Vehicles To Show'
              />
              <Tailselect
                id='dashboard-vehicles-serviceCostsVehicleSort-filter'
                name='serviceCostsVehicleSort'
                multiple={false}
                value={serviceCostsFilters.serviceCostsVehicleSort}
                options={filterOptions.vehicles.efficiencyVehicleSort}
                onChange={handleVehiclesFilters}
                placeholder='Sorting method'
              />
            </div>
            <div className='text-center align-items-center my-auto'>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setStackedComparison(!stackedComparison);
                    }}
                    checked={stackedComparison}
                  />
                }
                label='Compare Scheduled / Unscheduled'
              />
            </div>
          </div>
          
        </React.Fragment>
      )}
    </div>
  );
}

export {KpiServiceCosts};
