import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function AddServiceButton(props){
    const buttonStyles = {};

    if (props.smallScreen) {
      buttonStyles.padding = '0';
      buttonStyles.fontSize = '3.25vw';
    }

    return (
      <button
        type='button'
        className='btn border-dark btn-primary col-12 py-2'
        data-toggle='modal'
        data-target='#shopview-add-history-modal'
        style={buttonStyles}
      >
        <FontAwesomeIcon icon='fa fa-plus' />  <strong>Record Service</strong>
      </button>
    )
}

export {AddServiceButton};
