import {customChartProperties, machineTypeMapping} from '../../app/utils';
import {unitsDisplayConversion} from '../../app/utils';

function roundDecimalPlaces(inputValue, decimalPlaces) {
  let value = inputValue;
  if (typeof value == 'string') {
    value = parseFloat(value.replace(/,/g, ''));
  }

  const roundingval = 100 * decimalPlaces;
  return (Math.round(value * roundingval) / roundingval).toFixed(decimalPlaces);
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function genEfficiencyData(
  taskInfoObject,
  blocksObj,
  fieldsObj,
  regionsObj,
  efficiencyFilters,
  displayedAxis,
  unitsSystem,
  showDetailedTooltipData,
  hourDollarRate,
  acreDollarRate,
  taskConfigDict
) {
  // Get units
  let units = 'km';
  if (unitsSystem == 'imperial') {
    units = 'mi';
  }

  const taskIds = Object.keys(taskInfoObject);
  const effData = {};
  const zoneTypes = ['Block', 'Field', 'Region'];
  taskIds.forEach((taskId) => {
    const taskInfo = taskInfoObject[taskId];
    let taskName = '';
    if (typeof taskConfigDict[taskId] !== 'undefined') {
      taskName = taskConfigDict[taskId].name;
    }
    zoneTypes.forEach((zoneType) => {
      let zones = Object.keys(taskInfo[zoneType]);

      // Sort task zones
      zones.sort((a, b) => {
        const zoneTaskInfoA = taskInfo[zoneType][a];
        const zoneTaskInfoB = taskInfo[zoneType][b];
        let calculatedDollarAmountA = 0;
        let calculatedDollarAmountB = 0;
        if (hourDollarRate != 0 && hourDollarRate != '') {
          calculatedDollarAmountA = hourDollarRate * zoneTaskInfoA.duration;
          calculatedDollarAmountB = hourDollarRate * zoneTaskInfoB.duration;
        }
        if (acreDollarRate != 0 && acreDollarRate != '') {
          calculatedDollarAmountA = acreDollarRate * zoneTaskInfoA.acreage;
          calculatedDollarAmountB = acreDollarRate * zoneTaskInfoB.acreage;
        }
        if (displayedAxis == 'Ac/Hr') {
          return efficiencyFilters.efficiencyZoneSort == 'Desc'
            ? zoneTaskInfoB.acPerHr - zoneTaskInfoA.acPerHr
            : zoneTaskInfoA.acPerHr - zoneTaskInfoB.acPerHr;
        } else if (displayedAxis == 'Speed') {
          return efficiencyFilters.efficiencyZoneSort == 'Desc'
            ? zoneTaskInfoB.avgSpeed - zoneTaskInfoA.avgSpeed
            : zoneTaskInfoA.avgSpeed - zoneTaskInfoB.avgSpeed;
        } else if (displayedAxis == 'Hr/Ac') {
          if (zoneTaskInfoB.acPerHr == 0 && zoneTaskInfoA.acPerHr == 0) {
            return 0;
          } else if (zoneTaskInfoA.acPerHr == 0) {
            return efficiencyFilters.efficiencyZoneSort == 'Desc' ? 1 : -1;
          } else if (zoneTaskInfoB.acPerHr == 0) {
            return efficiencyFilters.efficiencyZoneSort == 'Desc' ? -1 : 1;
          } else {
            return efficiencyFilters.efficiencyZoneSort == 'Desc'
              ? 1 / zoneTaskInfoB.acPerHr - 1 / zoneTaskInfoA.acPerHr
              : 1 / zoneTaskInfoA.acPerHr - 1 / zoneTaskInfoB.acPerHr;
          }
        } else if (displayedAxis == 'Total $') {
          return efficiencyFilters.efficiencyZoneSort == 'Desc'
            ? calculatedDollarAmountB - calculatedDollarAmountA
            : calculatedDollarAmountA - calculatedDollarAmountB;
        }
      });
      if (efficiencyFilters.efficiencyZoneSort == 'Alphabetical') {
        zones.sort((a, b) => {
          return a.localeCompare(b);
        });
      }
      if (efficiencyFilters.efficiencyZoneCount > 0) {
        if (zones.length > efficiencyFilters.efficiencyZoneCount) {
          zones = zones.slice(0, efficiencyFilters.efficiencyZoneCount);
        }
      }

      const acHrs = [];
      const hrPerAc = [];
      const speeds = [];
      const totalDollars = [];
      zones.forEach((zone) => {
        const zoneTaskInfo = taskInfo[zoneType][zone];
        let calculatedDollarAmount = 0;
        if (hourDollarRate != 0 && hourDollarRate != '') {
          calculatedDollarAmount = hourDollarRate * zoneTaskInfo.duration;
        }
        if (acreDollarRate != 0 && acreDollarRate != '') {
          calculatedDollarAmount = acreDollarRate * zoneTaskInfo.acreage;
        }
        acHrs.push(zoneTaskInfo.acPerHr);
        hrPerAc.push(1 / zoneTaskInfo.acPerHr);
        speeds.push(unitsDisplayConversion(zoneTaskInfo.avgSpeed * 100, units) / 100);
        totalDollars.push(calculatedDollarAmount);
      });

      if (!Object.prototype.hasOwnProperty.call(effData, zoneType)) {
        effData[zoneType] = {};
      }

      effData[zoneType][taskId] = {
        labels: zones,
        datasets: [
          {
            label: 'Ac/Hr',
            data: acHrs,
            borderWidth: 1,
            yAxisID: 'AcHr',
            backgroundColor: customChartProperties.colorGreen,
            tooltip: {
              callbacks: {
                label: (context) => {
                  const formattedValue = context.formattedValue;
                  const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ac/hr`];
                  if (showDetailedTooltipData) {
                    const taskData = taskInfo[zoneType][zones[context.dataIndex]];
                    if (taskData?.acreage && taskData?.duration) {
                      const totalAcreageTooltip = `Total Acreage: ${roundDecimalPlaces(taskData.acreage, 2)} ac`;
                      const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                      tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                    }

                    if (zoneType == 'Block') {
                      let rowSpacingString = `Not Assigned`;
                      const blockObj = blocksObj[zones[context.dataIndex]];
                      if (blockObj.row_spacing_meters) {
                        rowSpacingString =
                          unitsSystem == 'imperial'
                            ? `${roundDecimalPlaces(unitsDisplayConversion(blockObj.row_spacing_meters, 'ft'), 2)} ft`
                            : `${roundDecimalPlaces(blockObj.row_spacing_meters, 2)} m`;
                      }
                      const rowSpacingTooltip = `Row Spacing: ${rowSpacingString}`;
                      tooltipRows.push(rowSpacingTooltip);
                    }
                  }
                  return tooltipRows;
                },
              },
            },
          },
          {
            label: 'Hr/Ac',
            data: hrPerAc,
            borderWidth: 1,
            yAxisID: 'HrAc',
            backgroundColor: customChartProperties.colorYellow,
            tooltip: {
              callbacks: {
                label: (context) => {
                  const formattedValue = context.formattedValue;
                  const tooltipRows = [`${roundDecimalPlaces(formattedValue, 1)} hr/ac`];
                  if (showDetailedTooltipData) {
                    const taskData = taskInfo[zoneType][zones[context.dataIndex]];
                    if (taskData?.acreage && taskData?.duration) {
                      const totalAcreageTooltip = `Total Acreage: ${roundDecimalPlaces(taskData.acreage, 2)} ac`;
                      const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                      tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                    }
                    if (zoneType == 'Block') {
                      let rowSpacingString = `Not Assigned`;
                      const blockObj = blocksObj[zones[context.dataIndex]];
                      if (blockObj.row_spacing_meters) {
                        rowSpacingString =
                          unitsSystem == 'imperial'
                            ? `${roundDecimalPlaces(unitsDisplayConversion(blockObj.row_spacing_meters, 'ft'), 2)} ft`
                            : `${roundDecimalPlaces(blockObj.row_spacing_meters, 2)} m`;
                      }
                      const rowSpacingTooltip = `Row Spacing: ${rowSpacingString}`;
                      tooltipRows.push(rowSpacingTooltip);
                    }
                  }
                  return tooltipRows;
                },
              },
            },
          },
          {
            label: 'Speed',
            data: speeds,
            borderWidth: 1,
            yAxisID: 'Speed',
            backgroundColor: customChartProperties.colorBlue,
            tooltip: {
              callbacks: {
                label: (context) => {
                  const formattedValue = context.formattedValue;
                  return ` ${roundDecimalPlaces(formattedValue, 1)} ${units}/hr`;
                },
              },
            },
          },
          {
            label: 'Total $',
            data: totalDollars,
            borderWidth: 1,
            yAxisID: 'Total $',
            backgroundColor: customChartProperties.colorBlue,
            tooltip: {
              callbacks: {
                label: (context) => {
                  const formattedValue = context.formattedValue;
                  return `Total Dollars: ${formatter.format(roundDecimalPlaces(formattedValue, 2))}`;
                },
              },
            },
          },
          {
            type: 'line',
            label: 'Target',
            backgroundColor: 'red',
            data: [],
            yAxisID: 'TargetAxis',
          },
        ],
      };
    });
  });

  // Add totals task agnostic for blocks
  let blocks = Object.keys(blocksObj);
  let acHrs = [];
  let hrPerAc = [];
  let speeds = [];
  let totalDollars = [];
  // Sort Fields totals
  blocks.sort((a, b) => {
    const blockA = blocksObj[a];
    const blockB = blocksObj[b];
    let calculatedDollarAmountA = 0;
    let calculatedDollarAmountB = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmountA = hourDollarRate * blockA.duration;
      calculatedDollarAmountB = hourDollarRate * blockB.duration;
    }
    if (acreDollarRate != 0 && acreDollarRate != '') {
      calculatedDollarAmountA = acreDollarRate * blockA.acreage;
      calculatedDollarAmountB = acreDollarRate * blockB.acreage;
    }
    if (displayedAxis == 'Ac/Hr') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? blockB.acPerHr - blockA.acPerHr
        : blockA.acPerHr - blockB.acPerHr;
    } else if (displayedAxis == 'Speed') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? blockB.avgSpeed - blockA.avgSpeed
        : blockA.avgSpeed - blockB.avgSpeed;
    } else if (displayedAxis == 'Hr/Ac') {
      if (blockB.acPerHr == 0 && blockA.acPerHr == 0) {
        return 0;
      } else if (blockA.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? 1 : -1;
      } else if (blockB.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? -1 : 1;
      } else {
        return efficiencyFilters.efficiencyZoneSort == 'Desc'
          ? 1 / blockB.acPerHr - 1 / blockA.acPerHr
          : 1 / blockA.acPerHr - 1 / blockB.acPerHr;
      }
    } else if (displayedAxis == 'Total $') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? calculatedDollarAmountB - calculatedDollarAmountA
        : calculatedDollarAmountA - calculatedDollarAmountB;
    }
  });
  if (efficiencyFilters.efficiencyZoneSort == 'Alphabetical') {
    blocks.sort((a, b) => {
      return a.localeCompare(b);
    });
  }
  if (efficiencyFilters.efficiencyZoneCount > 0) {
    if (blocks.length > efficiencyFilters.efficiencyZoneCount) {
      blocks = blocks.slice(0, efficiencyFilters.efficiencyZoneCount);
    }
  }

  blocks.forEach((blockName) => {
    let calculatedDollarAmount = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmount = hourDollarRate * blocksObj[blockName].duration;
    }
    if (acreDollarRate != 0 && acreDollarRate != '') {
      calculatedDollarAmount = acreDollarRate * blocksObj[blockName].acreage;
    }
    acHrs.push(blocksObj[blockName].acPerHr);
    hrPerAc.push(1 / blocksObj[blockName].acPerHr);
    speeds.push(unitsDisplayConversion(blocksObj[blockName].avgSpeed * 100, units) / 100);
    totalDollars.push(calculatedDollarAmount);
  });
  if (effData.hasOwnProperty('Block')) {
    effData['Block']['Total'] = {
      labels: blocks,
      datasets: [
        {
          label: 'Ac/Hr',
          data: acHrs,
          borderWidth: 1,
          yAxisID: 'AcHr',
          backgroundColor: customChartProperties.colorGreen,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ac/hr`];
                if (showDetailedTooltipData) {
                  const taskData = blocksObj[blocks[context.dataIndex]];
                  if (taskData?.acreage && taskData?.duration) {
                    const totalAcreageTooltip = `Total Acreage: ${roundDecimalPlaces(taskData.acreage, 2)} ac`;
                    const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                    let rowSpacingString = `Not Assigned`;
                    if (taskData.row_spacing_meters) {
                      rowSpacingString =
                        unitsSystem == 'imperial'
                          ? `${roundDecimalPlaces(unitsDisplayConversion(taskData.row_spacing_meters, 'ft'), 2)} ft`
                          : `${roundDecimalPlaces(taskData.row_spacing_meters, 2)} m`;
                    }
                    const rowSpacingTooltip = `Row Spacing: ${rowSpacingString}`;
                    tooltipRows.push(totalAcreageTooltip, totalDurationTooltip, rowSpacingTooltip);
                  }
                }
                return tooltipRows;
              },
            },
          },
        },
        {
          label: 'Hr/Ac',
          data: hrPerAc,
          borderWidth: 1,
          yAxisID: 'HrAc',
          backgroundColor: customChartProperties.colorYellow,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                const tooltipRows = [`${roundDecimalPlaces(formattedValue, 1)} hr/ac`];
                if (showDetailedTooltipData) {
                  const taskData = blocksObj[blocks[context.dataIndex]];
                  if (taskData?.acreage && taskData?.duration) {
                    const totalAcreageTooltip = `Total Acreage: ${roundDecimalPlaces(taskData.acreage, 2)} ac`;
                    const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                    let rowSpacingString = `Not Assigned`;
                    if (taskData.row_spacing_meters) {
                      rowSpacingString =
                        unitsSystem == 'imperial'
                          ? `${roundDecimalPlaces(unitsDisplayConversion(taskData.row_spacing_meters, 'ft'), 2)} ft`
                          : `${roundDecimalPlaces(taskData.row_spacing_meters, 2)} m`;
                    }
                    const rowSpacingTooltip = `Row Spacing: ${rowSpacingString}`;
                    tooltipRows.push(totalAcreageTooltip, totalDurationTooltip, rowSpacingTooltip);
                  }
                }
                return tooltipRows;
              },
            },
          },
        },
        {
          label: 'Speed',
          data: speeds,
          borderWidth: 1,
          yAxisID: 'Speed',
          backgroundColor: customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                return ` ${roundDecimalPlaces(formattedValue, 1)} ${units}/hr`;
              },
            },
          },
        },
        {
          label: 'Total $',
          data: totalDollars,
          borderWidth: 1,
          yAxisID: 'Total $',
          backgroundColor: customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                return `Total Dollars: ${formatter.format(roundDecimalPlaces(formattedValue, 2))}`;
              },
            },
          },
        },
        {
          type: 'line',
          label: 'Target',
          backgroundColor: 'red',
          data: [],
          yAxisID: 'TargetAxis',
        },
      ],
    };
  }

  // Add totals task agnostic for fields
  let fields = Object.keys(fieldsObj);
  acHrs = [];
  hrPerAc = [];
  speeds = [];
  totalDollars = [];
  // Sort Fields totals
  fields.sort((a, b) => {
    const fieldA = fieldsObj[a];
    const fieldB = fieldsObj[b];
    let calculatedDollarAmountA = 0;
    let calculatedDollarAmountB = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmountA = hourDollarRate * fieldA.duration;
      calculatedDollarAmountB = hourDollarRate * fieldB.duration;
    }
    if (acreDollarRate != 0 && acreDollarRate != '') {
      calculatedDollarAmountA = acreDollarRate * fieldA.acreage;
      calculatedDollarAmountB = acreDollarRate * fieldB.acreage;
    }
    if (displayedAxis == 'Ac/Hr') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? fieldB.acPerHr - fieldA.acPerHr
        : fieldA.acPerHr - fieldB.acPerHr;
    } else if (displayedAxis == 'Speed') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? fieldB.avgSpeed - fieldA.avgSpeed
        : fieldA.avgSpeed - fieldB.avgSpeed;
    } else if (displayedAxis == 'Hr/Ac') {
      if (fieldB.acPerHr == 0 && fieldA.acPerHr == 0) {
        return 0;
      } else if (fieldA.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? 1 : -1;
      } else if (fieldB.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? -1 : 1;
      } else {
        return efficiencyFilters.efficiencyZoneSort == 'Desc'
          ? 1 / fieldB.acPerHr - 1 / fieldA.acPerHr
          : 1 / fieldA.acPerHr - 1 / fieldB.acPerHr;
      }
    } else if (displayedAxis == 'Total $') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? calculatedDollarAmountB - calculatedDollarAmountA
        : calculatedDollarAmountA - calculatedDollarAmountB;
    }
  });
  if (efficiencyFilters.efficiencyZoneSort == 'Alphabetical') {
    fields.sort((a, b) => {
      return a.localeCompare(b);
    });
  }
  if (efficiencyFilters.efficiencyZoneCount > 0) {
    if (fields.length > efficiencyFilters.efficiencyZoneCount) {
      fields = fields.slice(0, efficiencyFilters.efficiencyZoneCount);
    }
  }

  fields.forEach((fieldName) => {
    let calculatedDollarAmount = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmount = hourDollarRate * fieldsObj[fieldName].duration;
    }
    if (acreDollarRate != 0 && acreDollarRate != '') {
      calculatedDollarAmount = acreDollarRate * fieldsObj[fieldName].acreage;
    }
    acHrs.push(fieldsObj[fieldName].acPerHr);
    hrPerAc.push(1 / fieldsObj[fieldName].acPerHr);
    speeds.push(unitsDisplayConversion(fieldsObj[fieldName].avgSpeed * 100, units) / 100);
    totalDollars.push(calculatedDollarAmount);
  });
  if (effData.hasOwnProperty('Field')) {
    effData['Field']['Total'] = {
      labels: fields,
      datasets: [
        {
          label: 'Ac/Hr',
          data: acHrs,
          borderWidth: 1,
          yAxisID: 'AcHr',
          backgroundColor: customChartProperties.colorGreen,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ac/hr`];
                if (showDetailedTooltipData) {
                  const taskData = fieldsObj[fields[context.dataIndex]];
                  if (taskData?.acreage && taskData?.duration) {
                    const totalAcreageTooltip = `Total Acreage: ${roundDecimalPlaces(taskData.acreage, 2)} ac`;
                    const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                    tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                  }
                }
                return tooltipRows;
              },
            },
          },
        },
        {
          label: 'Hr/Ac',
          data: hrPerAc,
          borderWidth: 1,
          yAxisID: 'HrAc',
          backgroundColor: customChartProperties.colorYellow,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                const tooltipRows = [`${roundDecimalPlaces(formattedValue, 1)} hr/ac`];
                if (showDetailedTooltipData) {
                  const taskData = fieldsObj[fields[context.dataIndex]];
                  if (taskData?.acreage && taskData?.duration) {
                    const totalAcreageTooltip = `Total Acreage: ${roundDecimalPlaces(taskData.acreage, 2)} ac`;
                    const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                    tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                  }
                }
                return tooltipRows;
              },
            },
          },
        },
        {
          label: 'Speed',
          data: speeds,
          borderWidth: 1,
          yAxisID: 'Speed',
          backgroundColor: customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                return ` ${roundDecimalPlaces(formattedValue, 1)} ${units}/hr`;
              },
            },
          },
        },
        {
          label: 'Total $',
          data: totalDollars,
          borderWidth: 1,
          yAxisID: 'Total $',
          backgroundColor: customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                return `Total Dollars: ${formatter.format(roundDecimalPlaces(formattedValue, 2))}`;
              },
            },
          },
        },
        {
          type: 'line',
          label: 'Target',
          backgroundColor: 'red',
          data: [],
          yAxisID: 'TargetAxis',
        },
      ],
    };
  }

  // Add totals task agnostic for regions
  let regions = Object.keys(regionsObj);
  // Sort Regions totals
  regions.sort((a, b) => {
    const regionA = regionsObj[a];
    const regionB = regionsObj[b];

    let calculatedDollarAmountA = 0;
    let calculatedDollarAmountB = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmountA = hourDollarRate * regionA.duration;
      calculatedDollarAmountB = hourDollarRate * regionB.duration;
    }
    if (acreDollarRate != 0 && acreDollarRate != '') {
      calculatedDollarAmountA = acreDollarRate * regionA.acreage;
      calculatedDollarAmountB = acreDollarRate * regionB.acreage;
    }
    if (displayedAxis == 'Ac/Hr') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? regionB.acPerHr - regionA.acPerHr
        : regionA.acPerHr - regionB.acPerHr;
    } else if (displayedAxis == 'Speed') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? regionB.avgSpeed - regionA.avgSpeed
        : regionA.avgSpeed - regionB.avgSpeed;
    } else if (displayedAxis == 'Hr/Ac') {
      if (regionB.acPerHr == 0 && regionA.acPerHr == 0) {
        return 0;
      } else if (regionA.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? 1 : -1;
      } else if (regionB.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? -1 : 1;
      } else {
        return efficiencyFilters.efficiencyZoneSort == 'Desc'
          ? 1 / regionB.acPerHr - 1 / regionA.acPerHr
          : 1 / regionA.acPerHr - 1 / regionB.acPerHr;
      }
    } else if (displayedAxis == 'Total $') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? calculatedDollarAmountB - calculatedDollarAmountA
        : calculatedDollarAmountA - calculatedDollarAmountB;
    }
  });
  if (efficiencyFilters.efficiencyZoneSort == 'Alphabetical') {
    regions.sort((a, b) => {
      return a.localeCompare(b);
    });
  }

  if (efficiencyFilters.efficiencyZoneCount > 0) {
    if (regions.length > efficiencyFilters.efficiencyZoneCount) {
      regions = regions.slice(0, efficiencyFilters.efficiencyZoneCount);
    }
  }

  acHrs = [];
  hrPerAc = [];
  speeds = [];
  totalDollars = [];
  regions.forEach((regionName) => {
    let calculatedDollarAmount = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmount = hourDollarRate * regionsObj[regionName].duration;
    }
    if (acreDollarRate != 0 && acreDollarRate != '') {
      calculatedDollarAmount = acreDollarRate * regionsObj[regionName].acreage;
    }

    acHrs.push(regionsObj[regionName].acPerHr);
    hrPerAc.push(1 / regionsObj[regionName].acPerHr);
    speeds.push(unitsDisplayConversion(regionsObj[regionName].avgSpeed * 100, units) / 100);
    totalDollars.push(calculatedDollarAmount);
  });

  if (effData.hasOwnProperty('Region')) {
    effData['Region']['Total'] = {
      labels: regions,
      datasets: [
        {
          label: 'Ac/Hr',
          data: acHrs,
          borderWidth: 1,
          yAxisID: 'AcHr',
          backgroundColor: customChartProperties.colorGreen,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ac/hr`];
                if (showDetailedTooltipData) {
                  const taskData = regionsObj[regions[context.dataIndex]];
                  if (taskData?.acreage && taskData?.duration) {
                    const totalAcreageTooltip = `Total Acreage: ${roundDecimalPlaces(taskData.acreage, 2)} ac`;
                    const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                    tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                  }
                }
                return tooltipRows;
              },
            },
          },
        },
        {
          label: 'Hr/Ac',
          data: hrPerAc,
          borderWidth: 1,
          yAxisID: 'HrAc',
          backgroundColor: customChartProperties.colorYellow,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                const tooltipRows = [`${roundDecimalPlaces(formattedValue, 1)} hr/ac`];
                if (showDetailedTooltipData) {
                  const taskData = regionsObj[regions[context.dataIndex]];
                  if (taskData?.acreage && taskData?.duration) {
                    const totalAcreageTooltip = `Total Acreage: ${roundDecimalPlaces(taskData.acreage, 2)} ac`;
                    const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                    tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                  }
                }
                return tooltipRows;
              },
            },
          },
        },
        {
          label: 'Speed',
          data: speeds,
          borderWidth: 1,
          yAxisID: 'Speed',
          backgroundColor: customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                return ` ${roundDecimalPlaces(formattedValue, 1)} ${units}/hr`;
              },
            },
          },
        },
        {
          label: 'Total $',
          data: totalDollars,
          borderWidth: 1,
          yAxisID: 'Total $',
          backgroundColor: customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                return `Total Dollars: ${formatter.format(roundDecimalPlaces(formattedValue, 2))}`;
              },
            },
          },
        },
        {
          type: 'line',
          label: 'Target',
          backgroundColor: 'red',
          data: [],
          yAxisID: 'TargetAxis',
        },
      ],
    };
  }

  return effData;
}

/**
 *
 * @param {*} num1
 * @param {*} num2
 * @param {*} inFieldTarget
 * @param {*} inFieldTargetPercent
 * @return {*}
 */
export function inVsOutPie(num1, num2, inFieldTarget, inFieldTargetPercent) {
  let data = [num1, num2];
  let labels = ['In Field', 'Out of Field'];
  let backgroundColors = [customChartProperties.colorGreen, customChartProperties.colorRed];

  if (num1 == 0 && num2 == 0) {
    labels = ['No Data'];
    backgroundColors = [customChartProperties.colorGrey];
    data = [0.01];
  }

  if (inFieldTargetPercent) {
    data.push(0);
    labels.push('Target In Field');
    backgroundColors.push(customChartProperties.colorBlue);
  }

  return {
    labels: labels,
    datasets: [
      {
        label: `In Field vs. Out of Field Time`,
        target: inFieldTargetPercent,
        data: data,
        backgroundColor: backgroundColors,
        borderWidth: 1,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 1)} hours`;
            },
          },
        },
      },
    ],
  };
}

/**
 *
 * @param {*} machineUsageData
 * @param {*} filters
 * @param {*} daysDiff
 * @param {*} typesData
 * @param {*} vehicleSNDict
 * @param {*} activeDevices
 * @param {*} unitsSystem
 * @param {*} hourRate
 * @param {*} fuelRate value saved in metric
 * @param {*} fuelCost value saved in metric
 * @return {*}
 */
export function genTotalMachineUsage(
  machineUsageData,
  filters,
  daysDiff,
  typesData,
  vehicleSNDict,
  activeDevices,
  unitsSystem,
  hourRate,
  fuelRate,
  fuelCost
) {
  let vehicleKeys = Object.keys(machineUsageData).filter((vehicleSN) => {
    return (
      Object.prototype.hasOwnProperty.call(vehicleSNDict, vehicleSN) && vehicleSNDict[vehicleSN].coverageActive == true
    );
  });
  if (filters.usageVehicleSNs.length > 0) {
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      return filters.usageVehicleSNs.indexOf(vehicleSN) !== -1;
    });
  }

  if (filters.usageVehicleSNs.length > 0) {
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      return filters.usageVehicleSNs.indexOf(vehicleSN) !== -1;
    });
  }

  if (filters.usageVehicleType.length > 0) {
    const filterVehicleTypes = filters.usageVehicleType.map((type) => {
      return parseInt(type);
    });
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      const vehicleType =
        vehicleSNDict[vehicleSN]?.machineType != undefined ? vehicleSNDict[vehicleSN]?.machineType : 0;
      return filterVehicleTypes.includes(vehicleType);
    });
  }

  vehicleKeys.sort((a, b) => {
    const usageA = machineUsageData[a].totalDuration;
    const usageB = machineUsageData[b].totalDuration;
    return filters.usageVehicleSort == 'Desc' ? usageB - usageA : usageA - usageB;
  });

  if (filters.usageVehicleSort == 'Alphabetical') {
    vehicleKeys.sort((a, b) => {
      const nameA = machineUsageData[a].vehicleName;
      const nameB = machineUsageData[b].vehicleName;
      return nameA.localeCompare(nameB);
    });
  }

  if (['DistDesc', 'DistAsc'].includes(filters.usageVehicleSort)) {
    vehicleKeys.sort((a, b) => {
      const distA = machineUsageData[a].distance;
      const distB = machineUsageData[b].distance;
      return filters.usageVehicleSort == 'DistDesc' ? distB - distA : distA - distB;
    });
  }

  // let singleVehicleTypeAverageHourGraph = null;
  let vehicleType = null;
  if (filters.usageVehicleType.length == 1) {
    vehicleType = filters.usageVehicleType[0];
  }

  // assign 0 to rates if empty
  hourRate = hourRate == '' || !hourRate ? 0 : hourRate;
  fuelRate = fuelRate == '' || !fuelRate ? 0 : fuelRate;
  fuelCost = fuelCost == '' || !fuelCost ? 0 : fuelCost;
  const fuelRateImperial = fuelRate / 3.785;
  if (unitsSystem == 'imperial') {
    // Convert L/Hr to Gal/Hr
    fuelRate = fuelRate / 3.785;
    // Convert $/L to $/Gal
    fuelCost = fuelCost * 3.785;
  }
  const usageValues = [];
  const avgHours = [];
  const totalDistance = [];
  const fuelUsage = []; // Machine Hours * Fuel Rate
  const totalFuelCost = []; // Machine Hours * Fuel Rate * Fuel Cost
  const machineOperationCost = []; // Machine Hours * Hour Rate
  const ghg = []; // Machine Hours * Fuel Rate * 10180 / 1000000, CO2 diesel emissions is 10180 gram/gallon

  // Temporarily Removed Average Line to revisit in V2
  // const avgVehicleTypeLine = [];
  let vehicleKeysFiltered = [];
  vehicleKeys.forEach((vehicleSN) => {
    const vehDoc = vehicleSNDict[vehicleSN];

    let beSolType = false;
    if (vehDoc?.geotabDevice?.serialNumber) {
      // BeWhere Serial in lower case
      const bewhereSerialRegex = /btsae[0-9]{14}/g;
      const geotabComment = activeDevices.geotab[vehDoc?.geotabDevice?.id]?.comment;
      if (geotabComment) {
        // convert comment to all lowercase to avoid missing serial due to case sensitivity
        const beWhereSerialFound = geotabComment.toLowerCase().match(bewhereSerialRegex);
        if (beWhereSerialFound) {
          beSolType = true;
        }
      }
    }

    if (!beSolType) {
      const veh = machineUsageData[vehicleSN];
      let vehTotalDistance = veh.distance;
      const vehGHG = (veh.totalDuration * fuelRateImperial * 10180) / 1000000;
      if (unitsSystem == 'imperial') {
        // Convert Distance from km to miles
        vehTotalDistance = parseFloat(unitsDisplayConversion(vehTotalDistance, 'mi'));
      }

      usageValues.push(veh.totalDuration);
      avgHours.push(veh.totalDuration / daysDiff);
      totalDistance.push(vehTotalDistance);
      fuelUsage.push(veh.totalDuration * fuelRate);
      totalFuelCost.push(veh.totalDuration * fuelRate * fuelCost);
      machineOperationCost.push(veh.totalDuration * hourRate);
      ghg.push(vehGHG);
      vehicleKeysFiltered.push(vehicleSN);
    }
    // Temporarily Removed Average Line to revisit in V2
    // if (vehicleType) {
    //   avgVehicleTypeLine.push(typesData[vehicleType].avgHrsPerDay / daysDiff);
    // }
  });

  // Temporarily Removed Average Line to revisit in V2
  // if (vehicleType) {
  //   singleVehicleTypeAverageHourGraph = {
  //     type: 'line',
  //     label: `${machineTypeMapping[vehicleType]} Avg Hrs/Day`,
  //     data: avgVehicleTypeLine,
  //     pointRadius: 1,
  //     order: 1,
  //     backgroundColor: customChartProperties.colorRed,
  //     borderColor: customChartProperties.colorRed,
  //     yAxisID: 'rightAxis',
  //     tooltip: {
  //       callbacks: {
  //         label: (context) => {
  //           const formattedValue = context.formattedValue;
  //           return ` ${roundDecimalPlaces(formattedValue, 1)} hours`;
  //         },
  //       },
  //     },
  //   };
  // }

  if (filters.usageVehicleCount > 0) {
    if (vehicleKeysFiltered.length > filters.usageVehicleCount) {
      vehicleKeysFiltered = vehicleKeysFiltered.slice(0, filters.usageVehicleCount);
    }
  }

  const machineUsage = {
    labels: vehicleKeysFiltered.map((vehicleSN) => {
      return machineUsageData[vehicleSN].vehicleName;
    }),
    datasets: [
      {
        type: 'bar',
        label: 'Usage Hours',
        data: usageValues,
        id: 'Total',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorBlue,
        borderColor: customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 1)} hours`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Avg Hrs/Day',
        data: avgHours,
        id: 'Avg',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'rightAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 1)} hours`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Travel Distance',
        data: totalDistance,
        id: 'Distance',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorBlue,
        borderColor: customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 1)} ${unitsSystem == 'imperial' ? 'mi' : 'km'}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Fuel Consumption',
        data: fuelUsage,
        id: 'Fuel',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorBlue,
        borderColor: customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)}  ${unitsSystem == 'imperial' ? 'Gal' : 'L'}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: fuelCost == '' || !fuelCost ? 'Machine Operating Cost' : 'Fuel Cost',
        data: fuelCost == '' || !fuelCost ? machineOperationCost : totalFuelCost,
        id: 'Cost',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorBlue,
        borderColor: customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` $${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'GHG emission',
        data: ghg,
        id: 'GHG',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorBlue,
        borderColor: customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)} Tonnes`;
            },
          },
        },
      },
    ],
  };

  // Temporarily Removed Average Line to revisit in V2
  // if (singleVehicleTypeAverageHourGraph) {
  //   machineUsage.datasets.push(singleVehicleTypeAverageHourGraph);
  // }

  return machineUsage;
}

/**
 *
 * @param {*} machineUsageData
 * @param {*} displayedAxis
 * @param {*} filters
 * @param {*} vehicleSNDict
 * @param {*} installTypes
 * @param {*} activeDevices
 * @param {boolean} showDetailedTooltipData
 * @return {*}
 */
export function genIdlePercentByMachine(
  machineUsageData,
  displayedAxis,
  filters,
  vehicleSNDict,
  installTypes,
  activeDevices,
  showDetailedTooltipData
) {
  let vehicleKeys = Object.keys(machineUsageData).filter((vehicleSN) => {
    return (
      Object.prototype.hasOwnProperty.call(vehicleSNDict, vehicleSN) && vehicleSNDict[vehicleSN].coverageActive == true
    );
  });

  // Create installTypesNameDict
  const installTypesNameDict = {};
  vehicleKeys.forEach((vehicleSN) => {
    installTypesNameDict[vehicleSNDict[vehicleSN].name] = installTypes?.[vehicleSN];
  });

  if (filters.idleVehicleSNs.length > 0) {
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      return filters.idleVehicleSNs.indexOf(vehicleSN) !== -1;
    });
  }

  if (filters.idleVehicleType.length > 0) {
    const filterVehicleTypes = filters.idleVehicleType.map((type) => {
      return parseInt(type);
    });
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      const vehicleType =
        vehicleSNDict[vehicleSN]?.machineType != undefined ? vehicleSNDict[vehicleSN]?.machineType : 0;
      return filterVehicleTypes.includes(vehicleType);
    });
  }

  if (displayedAxis == 'Idle %') {
    vehicleKeys.sort((a, b) => {
      const usageA = machineUsageData[a].idlePercent;
      const usageB = machineUsageData[b].idlePercent;
      return filters.idleVehicleSort == 'Desc' ? usageB - usageA : usageA - usageB;
    });
  } else {
    vehicleKeys.sort((a, b) => {
      const usageA = machineUsageData[a].idleTimeInHours;
      const usageB = machineUsageData[b].idleTimeInHours;
      return filters.idleVehicleSort == 'Desc' ? usageB - usageA : usageA - usageB;
    });
  }

  if (filters.idleVehicleSort == 'Alphabetical') {
    vehicleKeys.sort((a, b) => {
      const nameA = machineUsageData[a].vehicleName;
      const nameB = machineUsageData[b].vehicleName;
      return nameA.localeCompare(nameB);
    });
  }

  const idlePercentValues = [];
  const idleTimeHours = [];
  const colors = [];
  let vehWithNoData = false;
  let vehicleKeysFiltered = [];
  vehicleKeys.forEach((vehicleSN) => {
    const veh = machineUsageData[vehicleSN];
    const vehDoc = vehicleSNDict[vehicleSN];

    let beSolType = false;
    if (vehDoc?.geotabDevice?.serialNumber) {
      // BeWhere Serial in lower case
      const bewhereSerialRegex = /btsae[0-9]{14}/g;
      const geotabComment = activeDevices.geotab[vehDoc?.geotabDevice?.id]?.comment;
      if (geotabComment) {
        // convert comment to all lowercase to avoid missing serial due to case sensitivity
        const beWhereSerialFound = geotabComment.toLowerCase().match(bewhereSerialRegex);
        if (beWhereSerialFound) {
          beSolType = true;
        }
      }
    }

    if (!beSolType) {
      vehicleKeysFiltered.push(vehicleSN);

      idlePercentValues.push(veh.idlePercent);
      idleTimeHours.push(veh.idleTimeInHours);

      // Determine bar color
      if (installTypesNameDict?.[veh.vehicleName] == 'J1939 / OBD with no CAN') {
        colors.push(customChartProperties.colorGrey);
        vehWithNoData = true;
      } else {
        colors.push(customChartProperties.colorBlue);
      }
    }
  });

  if (filters.idleVehicleCount > 0) {
    if (vehicleKeysFiltered.length > filters.idleVehicleCount) {
      vehicleKeysFiltered = vehicleKeysFiltered.slice(0, filters.idleVehicleCount);
    }
  }

  const idlePercent = {
    labels: vehicleKeysFiltered.map((vehicleSN) => {
      return machineUsageData[vehicleSN].vehicleName;
    }),

    datasets: [
      {
        label: 'Idle %',
        data: idlePercentValues,
        backgroundColor: colors,
        legendBackgroundColor: customChartProperties.colorBlue,
        borderWidth: 0,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const tooltipRows = [`${roundDecimalPlaces(formattedValue, 1)}%`];
              if (showDetailedTooltipData) {
                const totalIdleTimeTooltip = `Total Idle Time: ${roundDecimalPlaces(
                  idleTimeHours[context.dataIndex],
                  2
                )} hr`;
                tooltipRows.push(totalIdleTimeTooltip);
              }
              return tooltipRows;
            },
          },
        },
      },
      {
        label: 'Idle Time (hr)',
        data: idleTimeHours,
        backgroundColor: colors,
        legendBackgroundColor: customChartProperties.colorBlue,
        borderWidth: 0,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} hr`];
              if (showDetailedTooltipData) {
                const totalIdleTimeTooltip = `Idle Percentage: ${roundDecimalPlaces(
                  idlePercentValues[context.dataIndex],
                  1
                )}%`;
                tooltipRows.push(totalIdleTimeTooltip);
              }
              return tooltipRows;
            },
          },
        },
      },
      ...(vehWithNoData
        ? [
            // Conditionally add dataset to produce legend entry
            {
              type: 'line',
              label: 'Missing Idle Data',
              backgroundColor: customChartProperties.colorGrey,
              data: [],
            },
          ]
        : []),
      {
        type: 'line',
        label: 'Target',
        backgroundColor: 'red',
        data: [],
        yAxisID: 'TargetAxis',
      },
    ],
  };

  return idlePercent;
}

/**
 *
 * @param {*} servicesData
 * @param {*} displayedAxis
 * @param {*} filters
 * @param {*} vehicleSNDict
 * @return {*}
 */
export function genServiceCostsGraphData(servicesData, displayedAxis, filters, vehicleSNDict) {
  let vehicleKeys = Object.keys(servicesData.vehicleServices);
  if (filters.serviceCostsVehicleSNs.length > 0) {
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      return filters.serviceCostsVehicleSNs.indexOf(vehicleSN) !== -1;
    });
  }

  if (filters.serviceCostsVehicleType.length > 0) {
    const filterVehicleTypes = filters.serviceCostsVehicleType.map((type) => {
      return parseInt(type);
    });
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      const vehicleType =
        vehicleSNDict[vehicleSN]?.machineType != undefined ? vehicleSNDict[vehicleSN]?.machineType : 0;
      return filterVehicleTypes.includes(vehicleType);
    });
  }

  vehicleKeys.sort((a, b) => {
    const partsCostsA = servicesData.vehicleServices[a].partsCosts;
    const partsCostsB = servicesData.vehicleServices[b].partsCosts;
    const laborCostsA = servicesData.vehicleServices[a].laborCosts;
    const laborCostsB = servicesData.vehicleServices[b].laborCosts;
    const laborTimeA = servicesData.vehicleServices[a].laborTime;
    const laborTimeB = servicesData.vehicleServices[b].laborTime;
    const nameA = servicesData.vehicleServices[a].vehicleName;
    const nameB = servicesData.vehicleServices[b].vehicleName;
    const totalCostsA = partsCostsA + laborCostsA;
    const totalCostsB = partsCostsB + laborCostsB;

    if (filters.serviceCostsVehicleSort == 'Alphabetical') {
      return nameA.localeCompare(nameB);
    }

    if (displayedAxis == 'Total') {
      return filters.serviceCostsVehicleSort == 'Desc' ? totalCostsB - totalCostsA : totalCostsA - totalCostsB;
    } else if (displayedAxis == 'PartsCosts') {
      return filters.serviceCostsVehicleSort == 'Desc' ? partsCostsB - partsCostsA : partsCostsA - partsCostsB;
    } else if (displayedAxis == 'LaborCosts') {
      return filters.serviceCostsVehicleSort == 'Desc' ? laborCostsB - laborCostsA : laborCostsA - laborCostsB;
    } else {
      return filters.serviceCostsVehicleSort == 'Desc' ? laborTimeB - laborTimeA : laborTimeA - laborTimeB;
    }
  });

  if (filters.serviceCostsVehicleCount > 0) {
    if (vehicleKeys.length > filters.serviceCostsVehicleCount) {
      vehicleKeys = vehicleKeys.slice(0, filters.serviceCostsVehicleCount);
    }
  }

  const totalCostsValues = [];
  const partsCostsValues = [];
  const laborCostsValues = [];
  const laborTimeValues = [];
  
  const totalScheduledCostsValues = [];
  const partsScheduledCostsValues = [];
  const laborScheduledCostsValues = [];
  const laborScheduledTimeValues = [];

  const totalUnScheduledCostsValues = [];
  const partsUnScheduledCostsValues = [];
  const laborUnScheduledCostsValues = [];
  const laborUnScheduledTimeValues = [];
  
  const vehicleKeysFiltered = [];
  vehicleKeys.forEach((vehicleSN) => {
    const veh = servicesData.vehicleServices[vehicleSN];

    totalCostsValues.push(veh.partsCosts + veh.laborCosts);
    partsCostsValues.push(veh.partsCosts);
    laborCostsValues.push(veh.laborCosts);
    laborTimeValues.push(veh.laborTime);

    totalScheduledCostsValues.push(veh.scheduledPartsCosts + veh.scheduledLaborCosts);
    partsScheduledCostsValues.push(veh.scheduledPartsCosts);
    laborScheduledCostsValues.push(veh.scheduledLaborCosts);
    laborScheduledTimeValues.push(veh.scheduledLaborTime);

    totalUnScheduledCostsValues.push(veh.unScheduledPartsCosts + veh.unScheduledLaborCosts);
    partsUnScheduledCostsValues.push(veh.unScheduledPartsCosts);
    laborUnScheduledCostsValues.push(veh.unScheduledLaborCosts);
    laborUnScheduledTimeValues.push(veh.unScheduledLaborTime);

    vehicleKeysFiltered.push(vehicleSN);
  });

  const chartLabels = vehicleKeysFiltered.map((vehicleSN) => {
    return servicesData.vehicleServices[vehicleSN].vehicleName;
  });

  const serviceCostsGraphData = {
    labels: chartLabels,
    datasets: [
      {
        type: 'bar',
        label: 'Service Costs',
        data: totalCostsValues,
        id: 'Total',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorBlue,
        borderColor: customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const totalVehCostsStr = `$${roundDecimalPlaces(formattedValue, 2)}`;
              const totalVehTimeStr = `${roundDecimalPlaces(laborTimeValues[context.dataIndex], 1)} hrs`;
              const tooltipRows = [totalVehCostsStr, totalVehTimeStr];
              return tooltipRows;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Parts Costs',
        data: partsCostsValues,
        id: 'PartsCosts',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorBlue,
        borderColor: customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `$${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Labor Costs',
        data: laborCostsValues,
        id: 'LaborCosts',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorBlue,
        borderColor: customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `$${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Labor Time',
        data: laborTimeValues,
        id: 'LaborTime',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorBlue,
        borderColor: customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `${roundDecimalPlaces(formattedValue, 1)} hrs`;
            },
          },
        },
      },
      // ============= UNSCHEDULED

      {
        type: 'bar',
        label: 'Unscheduled Service Costs',
        data: totalUnScheduledCostsValues,
        id: 'Total.UnScheduled',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorRed,
        borderColor: customChartProperties.colorRed,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const totalVehCostsStr = `$${roundDecimalPlaces(formattedValue, 2)}`;
              const totalVehTimeStr = `${roundDecimalPlaces(laborUnScheduledTimeValues[context.dataIndex], 1)} hrs`;
              const tooltipRows = [totalVehCostsStr, totalVehTimeStr];
              return tooltipRows;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Unscheduled Parts Costs',
        data: partsUnScheduledCostsValues,
        id: 'PartsCosts.UnScheduled',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorRed,
        borderColor: customChartProperties.colorRed,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `$${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Unscheduled Labor Costs',
        data: laborUnScheduledCostsValues,
        id: 'LaborCosts.UnScheduled',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorRed,
        borderColor: customChartProperties.colorRed,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `$${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Unscheduled Labor Time',
        data: laborUnScheduledTimeValues,
        id: 'LaborTime.UnScheduled',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorRed,
        borderColor: customChartProperties.colorRed,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `${roundDecimalPlaces(formattedValue, 1)} hrs`;
            },
          },
        },
      },
// ============= SCHEDULED
      {
        type: 'bar',
        label: 'Scheduled Service Costs',
        data: totalScheduledCostsValues,
        id: 'Total.Scheduled',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const totalVehCostsStr = `$${roundDecimalPlaces(formattedValue, 2)}`;
              const totalVehTimeStr = `${roundDecimalPlaces(laborScheduledTimeValues[context.dataIndex], 1)} hrs`;
              const tooltipRows = [totalVehCostsStr, totalVehTimeStr];
              return tooltipRows;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Scheduled Parts Costs',
        data: partsScheduledCostsValues,
        id: 'PartsCosts.Scheduled',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `$${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Scheduled Labor Costs',
        data: laborScheduledCostsValues,
        id: 'LaborCosts.Scheduled',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `$${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Scheduled Labor Time',
        data: laborScheduledTimeValues,
        id: 'LaborTime.Scheduled',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `${roundDecimalPlaces(formattedValue, 1)} hrs`;
            },
          },
        },
      }, 
    ],
  };

  return serviceCostsGraphData;
}

/**
 *
 * @param {*} vehiclesData
 * @param {*} filters
 * @return {*}
 */
export function genVehiclesWithoutTasks(vehiclesData, filters) {
  let vehicleKeys = Object.keys(vehiclesData);

  vehicleKeys = vehicleKeys.filter((vehicleSN) => {
    let vehicleInFilter = true;
    if (filters.missingTaskVehicleSNs.length > 0) {
      vehicleInFilter = filters.missingTaskVehicleSNs.indexOf(vehicleSN) !== -1;
    }
    const vehicleHasMissingTaskHours = vehiclesData[vehicleSN].missingTaskHours > 0;
    return vehicleInFilter && vehicleHasMissingTaskHours;
  });

  vehicleKeys.sort((a, b) => {
    const missingHoursA = vehiclesData[a].missingTaskHours;
    const missingHoursB = vehiclesData[b].missingTaskHours;
    return missingHoursB - missingHoursA;
  });

  // TODO
  const vehicleHrsValues = [];
  const vehicleAcValues = [];
  const vehicleGraph = {
    labels: vehicleKeys.map((vehicleSN) => {
      return vehiclesData[vehicleSN].vehicleName;
    }),

    datasets: [
      {
        label: 'Hrs',
        data: vehicleHrsValues,
        borderWidth: 0,
        yAxisID: 'leftAxis',
        backgroundColor: customChartProperties.colorGreen,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 1)} hours`;
            },
          },
        },
      },
      {
        label: 'Ac',
        data: vehicleAcValues,
        borderWidth: 0,
        yAxisID: 'rightAxis',
        backgroundColor: customChartProperties.colorBlue,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)} ac`;
            },
          },
        },
      },
    ],
  };
  vehicleKeys.forEach((vehicleSN) => {
    const veh = vehiclesData[vehicleSN];
    vehicleHrsValues.push(veh.missingTaskHours);
    vehicleAcValues.push(veh.missingTaskAcreage);
  });
  return vehicleGraph;
}

export function genMissingTaskData(regionsData, fieldsData, blocksData, filters) {
  // Get region data
  let regionEnteredTaskHours = 0;
  let regionMissingTaskHours = 0;
  const regionsNamesList = Object.keys(regionsData);
  regionsNamesList.forEach((region) => {
    if (filters.regions.length == 0 || filters.regions.includes(region)) {
      regionEnteredTaskHours += regionsData[region].enteredTaskHours;
      regionMissingTaskHours += regionsData[region].missingTaskHours;
    }
  });

  // Get field data
  let fieldEnteredTaskHours = 0;
  let fieldMissingTaskHours = 0;
  const fieldsNamesList = Object.keys(fieldsData);
  fieldsNamesList.forEach((field) => {
    if (filters.fields.length == 0 || filters.fields.includes(field)) {
      fieldEnteredTaskHours += fieldsData[field].enteredTaskHours;
      fieldMissingTaskHours += fieldsData[field].missingTaskHours;
    }
  });

  // Make region graph
  const regionsGraph = {
    labels: ['Task Entered', 'Missing Task'],
    datasets: [
      {
        label: 'Tasks Logged',
        data: [regionEnteredTaskHours, regionMissingTaskHours],
        backgroundColor: [customChartProperties.colorBlue, customChartProperties.colorRed],
        borderWidth: 1,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 1)} hours`;
            },
          },
        },
      },
    ],
  };

  // Make field graph
  const fieldsGraph = {
    labels: ['Task Entered', 'Missing Task'],
    datasets: [
      {
        label: 'Tasks Logged',
        data: [fieldEnteredTaskHours, fieldMissingTaskHours],
        backgroundColor: [customChartProperties.colorBlue, customChartProperties.colorRed],
        borderWidth: 1,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 1)} hours`;
            },
          },
        },
      },
    ],
  };

  const missingTaskGraphs = {
    'Field': fieldsGraph,
    'Region': regionsGraph,
    'FieldTotalHours': fieldEnteredTaskHours + fieldMissingTaskHours,
    'RegionTotalHours': regionEnteredTaskHours + regionMissingTaskHours,
  };

  return missingTaskGraphs;
}

export function genTasksLoggedByZone(regionsData, fieldsData, blocksData, filters) {
  // Regions
  const regionsNamesList = Object.keys(regionsData);
  let regionsNames = [];
  let regionsValues = [];

  regionsNamesList.sort((a, b) => {
    const tasksLoggedA =
      regionsData[a].enteredTaskHours > 0
        ? (regionsData[a].enteredTaskHours / (regionsData[a].enteredTaskHours + regionsData[a].missingTaskHours)) * 100
        : 0;
    const tasksLoggedB =
      regionsData[b].enteredTaskHours > 0
        ? (regionsData[b].enteredTaskHours / (regionsData[b].enteredTaskHours + regionsData[b].missingTaskHours)) * 100
        : 0;

    return filters.regionsSort == 'Desc' ? tasksLoggedB - tasksLoggedA : tasksLoggedA - tasksLoggedB;
  });

  if (filters.regionsSort == 'Alphabetical') {
    regionsNamesList.sort((a, b) => {
      return a.localeCompare(b);
    });
  }

  regionsNamesList.forEach((region) => {
    if (filters.regions.length == 0 || filters.regions.includes(region)) {
      const loggedValue =
        regionsData[region].enteredTaskHours > 0
          ? (regionsData[region].enteredTaskHours /
              (regionsData[region].enteredTaskHours + regionsData[region].missingTaskHours)) *
            100
          : 0;
      regionsValues.push(loggedValue);
      regionsNames.push(region);
    }
  });

  if (filters.regionsCount > 0) {
    if (regionsNamesList.length > filters.regionsCount) {
      regionsValues = regionsValues.slice(0, filters.regionsCount);
      regionsNames = regionsNames.slice(0, filters.regionsCount);
    }
  }

  const regionsGraph = {
    labels: regionsNames,
    datasets: [
      {
        label: 'Tasks Logged vs. Region',
        data: regionsValues,
        borderWidth: 0,
        backgroundColor: customChartProperties.colorGreen,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 1)}%`;
            },
          },
        },
      },
    ],
  };

  // Fields
  const fieldsNamesList = Object.keys(fieldsData);
  let fieldsNames = [];
  let fieldsValues = [];

  fieldsNamesList.sort((a, b) => {
    const tasksLoggedA =
      fieldsData[a].enteredTaskHours > 0
        ? (fieldsData[a].enteredTaskHours / (fieldsData[a].enteredTaskHours + fieldsData[a].missingTaskHours)) * 100
        : 0;
    const tasksLoggedB =
      fieldsData[b].enteredTaskHours > 0
        ? (fieldsData[b].enteredTaskHours / (fieldsData[b].enteredTaskHours + fieldsData[b].missingTaskHours)) * 100
        : 0;

    return filters.fieldsSort == 'Desc' ? tasksLoggedB - tasksLoggedA : tasksLoggedA - tasksLoggedB;
  });

  if (filters.fieldsSort == 'Alphabetical') {
    fieldsNamesList.sort((a, b) => {
      return a.localeCompare(b);
    });
  }

  fieldsNamesList.forEach((field) => {
    if (filters.fields.length == 0 || filters.fields.includes(field)) {
      const loggedValue =
        fieldsData[field].enteredTaskHours > 0
          ? (fieldsData[field].enteredTaskHours /
              (fieldsData[field].enteredTaskHours + fieldsData[field].missingTaskHours)) *
            100
          : 0;
      fieldsValues.push(loggedValue);
      fieldsNames.push(field);
    }
  });

  if (filters.fieldsCount > 0) {
    if (fieldsNamesList.length > filters.fieldsCount) {
      fieldsValues = fieldsValues.slice(0, filters.fieldsCount);
      fieldsNames = fieldsNames.slice(0, filters.fieldsCount);
    }
  }

  const fieldsGraph = {
    labels: fieldsNames,
    datasets: [
      {
        label: 'Tasks Logged vs. Field',
        data: fieldsValues,
        borderWidth: 0,
        backgroundColor: customChartProperties.colorGreen,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 1)}%`;
            },
          },
        },
      },
    ],
  };

  const tasksLoggedByZoneGraphs = {
    'Field': fieldsGraph,
    'Region': regionsGraph,
  };

  return tasksLoggedByZoneGraphs;
}

export function genPieDataServices(num1, num2) {
  return {
    labels: ['On Time', 'Late'],
    datasets: [
      {
        label: `Total Services Completed`,
        data: [num1, num2],
        backgroundColor: [customChartProperties.colorGreen, customChartProperties.colorYellow],
        borderWidth: 1,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${formattedValue}`;
            },
          },
        },
      },
    ],
  };
}

export function genPieDataInspections(totalResults) {
  const totalInspectionsResults = {
    labels: ['Pass', 'Failed'],
    datasets: [
      {
        label: 'Inspection Pass/Fail Rate',
        data: [totalResults.pass, totalResults.fail],
        backgroundColor: [customChartProperties.colorGreen, customChartProperties.colorYellow],
        borderWidth: 1,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${formattedValue}`;
            },
          },
        },
      },
    ],
  };
  return totalInspectionsResults;
}

/**
 *
 * @param {*} vehicleServices
 * @param {*} filters
 * @param {*} vehicleSNDict
 * @param {*} serviceIntervalSelected
 * @param {*} serviceIntervalSelectedUnit
 * @return {*}
 */
export function genServiceHealth(
  vehicleServices,
  filters,
  vehicleSNDict,
  serviceIntervalSelected,
  serviceIntervalSelectedUnit
) {
  let vehicleKeys = Object.keys(vehicleServices).filter((vehicleSN) => {
    const vehicleExist = Object.prototype.hasOwnProperty.call(vehicleSNDict, vehicleSN);
    const vehicleHasSelectedService =
      vehicleServices[vehicleSN].serviceStats[serviceIntervalSelected].overBy.length > 0;
    return vehicleExist && vehicleHasSelectedService;
  });
  const serviceHealthGroups = {};

  // Filter By VehicleSN
  if (filters.serviceHealthVehicleSNs.length > 0) {
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      return filters.serviceHealthVehicleSNs.indexOf(vehicleSN) !== -1;
    });
  }

  // Filter By Machine Type
  if (filters.serviceHealthVehicleType.length > 0) {
    const filterVehicleTypes = filters.serviceHealthVehicleType.map((type) => {
      return parseInt(type);
    });
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      const vehicleType =
        vehicleSNDict[vehicleSN]?.machineType != undefined ? vehicleSNDict[vehicleSN]?.machineType : 0;
      return filterVehicleTypes.includes(vehicleType);
    });
  }

  // Aggregate ServiceStats from vehicles
  for (let i = 0; i < vehicleKeys.length; i++) {
    const vehicleSN = vehicleKeys[i];
    const vehicleServiceStats = {...vehicleServices[vehicleSN].serviceStats[serviceIntervalSelected]};

    // If vehicle had Services of the selected Interval Type
    if (vehicleServiceStats.overBy.length > 0) {
      const vehicleType =
        vehicleSNDict[vehicleSN]?.machineType != undefined ? vehicleSNDict[vehicleSN]?.machineType : 0;

      if (filters.serviceHealthGroupByMachineType) {
        //  If vehicle type does not have Services Stat Object Initialized
        if (!Object.hasOwnProperty.call(serviceHealthGroups, vehicleType)) {
          serviceHealthGroups[vehicleType] = {
            intervals: [],
            overBy: [],
            vehicleCount: 0,
            name: machineTypeMapping[vehicleType],
          };
        }
        serviceHealthGroups[vehicleType].intervals.push(...vehicleServiceStats.intervals);
        serviceHealthGroups[vehicleType].overBy.push(...vehicleServiceStats.overBy);
        serviceHealthGroups[vehicleType].vehicleCount += 1;
      } else {
        vehicleServiceStats.averageOverValue =
          vehicleServiceStats.overBy.reduce((a, b) => {
            return a + b;
          }, 0) / vehicleServiceStats.overBy.length;
        vehicleServiceStats.averageInterval =
          vehicleServiceStats.overBy.reduce((a, b) => {
            return a + b;
          }, 0) / vehicleServiceStats.intervals.length;
        vehicleServiceStats.serviceCount = vehicleServiceStats.overBy.length;
        vehicleServiceStats.name = vehicleServices[vehicleSN].vehicleName;
        serviceHealthGroups[vehicleSN] = vehicleServiceStats;
      }
    }
  }

  let serviceHealthGroupKeys = vehicleKeys;

  // Add key stats to machineType grouped stats
  if (filters.serviceHealthGroupByMachineType) {
    const serviceMachineTypes = Object.keys(serviceHealthGroups);
    serviceMachineTypes.forEach((machineType) => {
      const groupedServiceStats = serviceHealthGroups[machineType];

      groupedServiceStats.averageOverValue =
        groupedServiceStats.overBy.reduce((a, b) => {
          return a + b;
        }, 0) / groupedServiceStats.overBy.length;
      groupedServiceStats.averageInterval =
        groupedServiceStats.overBy.reduce((a, b) => {
          return a + b;
        }, 0) / groupedServiceStats.intervals.length;
      groupedServiceStats.serviceCount = groupedServiceStats.overBy.length;
      serviceHealthGroups[machineType] = groupedServiceStats;
    });
    serviceHealthGroupKeys = serviceMachineTypes;
  }

  serviceHealthGroupKeys.sort((a, b) => {
    const usageA = serviceHealthGroups[a].averageOverValue;
    const usageB = serviceHealthGroups[b].averageOverValue;
    return filters.serviceHealthVehicleSort == 'Desc' ? usageB - usageA : usageA - usageB;
  });

  if (filters.serviceHealthVehicleSort == 'Alphabetical') {
    serviceHealthGroupKeys.sort((a, b) => {
      const nameA = serviceHealthGroups[a].name;
      const nameB = serviceHealthGroups[b].name;
      return nameA.localeCompare(nameB);
    });
  }

  if (!filters.serviceHealthGroupByMachineType && filters.serviceHealthVehicleCount > 0) {
    if (serviceHealthGroupKeys.length > filters.serviceHealthVehicleCount) {
      serviceHealthGroupKeys = serviceHealthGroupKeys.slice(0, filters.serviceHealthVehicleCount);
    }
  }

  const overByValues = serviceHealthGroupKeys.map((key) => {
    if (serviceIntervalSelected == 'odo' && serviceIntervalSelectedUnit.odo == 'mi') {
      return unitsDisplayConversion(serviceHealthGroups[key].averageOverValue, serviceIntervalSelectedUnit.odo);
    } else {
      return serviceHealthGroups[key].averageOverValue;
    }
  });

  const colors = overByValues.map((value) => {
    return value > 0 ? customChartProperties.colorYellow : customChartProperties.colorGreen;
  });

  const serviceHealth = {
    labels: serviceHealthGroupKeys.map((key) => {
      return serviceHealthGroups[key].name;
    }),

    datasets: [
      {
        label: 'Late Service',
        data: overByValues,
        backgroundColor: colors,
        legendBackgroundColor: customChartProperties.colorYellow,
        borderWidth: 1,
        borderSkipped: false,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const serviceUnit = serviceIntervalSelectedUnit[serviceIntervalSelected];
              const valueAbs = Math.abs(formattedValue.replace(/,/g, ''));
              const lateOrEarly = formattedValue < 0 ? 'Early' : 'Late';
              const averageOverByTooltip = `Average ${lateOrEarly} by: ${roundDecimalPlaces(
                valueAbs,
                1
              )} ${serviceUnit}`;

              const highestOverBy = Math.max(...serviceHealthGroups[serviceHealthGroupKeys[context.dataIndex]].overBy);
              let highestOverDueTooltip = `Largest Late value: No Late Services`;
              if (highestOverBy > 0) {
                highestOverDueTooltip = `Largest Late value: ${roundDecimalPlaces(highestOverBy, 1)} ${serviceUnit}`;
              }

              const totalserviceCount = serviceHealthGroups[serviceHealthGroupKeys[context.dataIndex]].serviceCount;
              const totalServiceCountTooltip = `Total Service Count: ${totalserviceCount}`;

              const tooltipRows = [averageOverByTooltip, highestOverDueTooltip, totalServiceCountTooltip];
              if (filters.serviceHealthGroupByMachineType) {
                const vehicleCount = serviceHealthGroups[serviceHealthGroupKeys[context.dataIndex]].vehicleCount;
                const vehicleCountTooltip = `Total Vehicles Count: ${vehicleCount}`;
                tooltipRows.push(vehicleCountTooltip);
              }
              return tooltipRows;
            },
          },
        },
      },
      {
        label: 'Early Service',
        data: [],
        backgroundColor: colors,
        legendBackgroundColor: customChartProperties.colorGreen,
      },
      {
        // Hide the Line element while keeping legend
        type: 'line',
        label: 'Target',
        backgroundColor: customChartProperties.colorRed,
        pointRadius: 0,
        borderWidth: 0,
        data: serviceHealthGroupKeys.map(() => {
          return 0;
        }),
        yAxisID: 'TargetAxis',
      },
    ],
  };

  return serviceHealth;
}
