import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Table, TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../components/Table';
import {Tailselect} from '../../components/Tailselect';
import {DateTime} from 'luxon';
import {searchFind, capitalizeFirstLetter, sendGAEvent, formatTime} from '../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  updateZoneZoom,
  updateEditCropviewValues,
  updateEditCropviewRecValues,
  updateMapView,
  updateVehicleZoom,
  updateTaskAcreageCapping,
  updateTaskAcreageCapTracker,
  updateTaskAcreageCapTrackerTask,
  updateVehicleSerialNumbersInDisplayedAnalytics,
} from './cropviewSlice';
import {setInitialValues} from './coverageModalSlice';
import {TabMenuTableWrapper} from '../../components/TabMenuTableWrapper';
import {CustomTablePagination} from '../../components/CustomTablePagination';

function CoverageTable(props) {
  const dispatch = useDispatch();

  const parentFieldLookup = useSelector((state) => {
    return state.cropview.parentFieldLookup;
  });
  const taskConfigIdDict = useSelector((state) => {
    return state.cropview.taskConfigIdDict;
  });
  const recList = useSelector((state) => {
    return state.cropview.recList;
  });
  const displayedTable = useSelector((state) => {
    return state.cropview.displayedTable;
  });
  const displayedColumn = useSelector((state) => {
    return state.cropview.displayedColumn;
  });
  const editCropview = useSelector((state) => {
    return state.cropview.editCropview;
  });
  const mapView = useSelector((state) => {
    return state.cropview.mapView;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const todayOnly = useSelector((state) => {
    return state.cropview.todayOnly;
  });
  const selectionDates = useSelector((state) => {
    return state.cropview.dates;
  });
  const taskAcreageCapTracker = useSelector((state) => {
    return state.cropview.taskAcreageCapTracker;
  });
  const vehicleLatestLocationDict = useSelector((state) => {
    return state.cropview.vehicleLatestLocationDict;
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [spans, setSpans] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [enableTaskAcreageCapping, setEnableTaskAcreageCapping] = useState(false); // Bool if acreage capping is enabled

  useEffect(() => {
    filterTableRows(props.tableData);
  }, [props.tableData, props.filters, searchText]);

  useEffect(() => {
    setPage(0);
  }, [props.type]);

  // Determine if global acreage capping is enabled
  useEffect(() => {
    let enableTaskAcreageCappingTemp = false;
    if (
      typeof customerSettings.general.enableTaskAcreageCapping != 'undefined' &&
      customerSettings.general.enableTaskAcreageCapping === true
    ) {
      enableTaskAcreageCappingTemp = true;
    }
    setEnableTaskAcreageCapping(enableTaskAcreageCappingTemp);
  }, [customerSettings]);

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }

    props.setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  function deepCopy(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      const newArray = [];
      for (const item of obj) {
        newArray.push(deepCopy(item));
      }
      return newArray;
    }

    const newObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = deepCopy(obj[key]);
      }
    }
    return newObj;
  }

  async function filterTableRows(tableData) {
    let applyAcreageCap = false;
    if (
      props.filters.tasks.length == 1 &&
      !props.filters.tasks.includes('') &&
      taskConfigIdDict.hasOwnProperty(props.filters.tasks[0])
    ) {
      // Get object of the selected task
      const taskObject = taskConfigIdDict[props.filters.tasks[0]];

      // Create DateTime objects from ISO strings of the time range selected
      const start = DateTime.fromISO(selectionDates.start);
      const end = DateTime.fromISO(selectionDates.end);

      // Calculate the difference in days
      const daysDifference = Math.floor(end.diff(start, 'days').days);

      // Determine if acreage capping should be applied. Only apply acreage capping if the task has it enabled,
      // the selection range is less than a year, and acreage capping is enabled for the customer
      applyAcreageCap =
        enableTaskAcreageCapping && taskObject != null && taskObject.enableAcreageCapping && daysDifference < 365;
    }

    // Filter props.tablerows based on filters and pagination to render limited amount of data on tables
    // Generate an array based on rowFilter function (return true or false) base on filters
    if (applyAcreageCap) {
      dispatch(updateTaskAcreageCapping(true));
      dispatch(updateTaskAcreageCapTrackerTask(props.filters.tasks[0]));
    } else {
      dispatch(updateTaskAcreageCapTracker({}));
      dispatch(updateTaskAcreageCapTrackerTask(''));
      dispatch(updateTaskAcreageCapping(false));
    }
    const tableDataCopy = deepCopy(tableData);
    const result = tableDataCopy.map((rowData) => {
      return rowFilter(rowData, tableDataCopy, applyAcreageCap);
    });

    // Filter rows of data based on filter results
    const resultRows = tableDataCopy.filter((row, index) => {
      return result[index] === true;
    });

    // Remove exisitng span from rows
    const updatingTaskAcreageCapTrackerObj = deepCopy(taskAcreageCapTracker);
    resultRows.map((row) => {
      if (applyAcreageCap && row.zoneAcreTotal > row.zoneMaxAreaZone) {
        if (!Object.prototype.hasOwnProperty.call(updatingTaskAcreageCapTrackerObj, row.taskName)) {
          updatingTaskAcreageCapTrackerObj[row.taskName] = {};
        }
        updatingTaskAcreageCapTrackerObj[row.taskName][row.zoneName] = Math.round(row.zoneMaxAreaZone * 100) / 100;
        row.capApplied = true;
      }
      delete row.span;
      delete row.taskSpan;
    });
    if (applyAcreageCap) {
      dispatch(updateTaskAcreageCapTracker(updatingTaskAcreageCapTrackerObj));
    }

    // Find merge span of rows
    let span = 0;
    let displayedColSpan = 0;
    for (let i = resultRows.length - 1; i > -1; i--) {
      // Span of zone column
      if (i != resultRows.length - 1 && resultRows[i].zoneName != resultRows[i + 1].zoneName) {
        resultRows[i + 1].span = span;
        span = 0;
      }
      if (i == 0) {
        resultRows[i].span = span + 1;
      }
      span += 1;

      // Span of displayed column on small screens
      if (
        i != resultRows.length - 1 &&
        (resultRows[i].zoneName != resultRows[i + 1].zoneName || resultRows[i].taskName != resultRows[i + 1].taskName)
      ) {
        resultRows[i + 1].taskSpan = displayedColSpan;
        displayedColSpan = 0;
      }
      if (i == 0) {
        resultRows[i].taskSpan = displayedColSpan + 1;
      }
      displayedColSpan += 1;
    }
    const spanCounts = [];
    resultRows.forEach((row) => {
      if (row.span != undefined) {
        spanCounts.push(row.span);
      }
    });
    setSpans(spanCounts);
    if (page > parseInt(resultRows.length / rowsPerPage)) {
      setPage(0);
    }

    // This count will not include vehicles without coverage UNLESS equipment table is selected.
    const vehicleSNsInFilter = countVehiclesInResultRows(resultRows);
    dispatch(updateVehicleSerialNumbersInDisplayedAnalytics(vehicleSNsInFilter));
    setTableData(resultRows);
  }

  function countVehiclesInResultRows(rows) {
    const result = [];
    if (rows && rows.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        // Don't want to include vehicles with 0 duration in the count
        if (!result.includes(rows[i].vehicleSN) && (!rows[i].hasOwnProperty("timeTotal") || rows[i].timeTotal > 0)) {
          result.push(rows[i].vehicleSN);
        }
      }
    }
    return result;
  }

  function rowFilter(rowData, tableDataList, applyAcreageCap) {
    if (props.type == 'equipment') {
      const equipmentInFilter =
        props.filters.equipment.includes(rowData.vehicleSN) || props.filters.equipment.length == 0;

      const row = [rowData.name, rowData.timeTotalFormatted, rowData.coverage];

      const search = searchFind(<div>{JSON.stringify(row)}</div>, searchText.toLowerCase().trim());

      return equipmentInFilter && search;
    }

    let zoneInFilter = true;
    if (props.type == 'block') {
      zoneInFilter = props.filters.zones.includes(rowData.zoneId) || props.filters.zones.length == 0;
    } else if (props.type == 'field') {
      const fieldList = [];
      for (let i = 0; i < props.filters.zones.length; i++) {
        if (!fieldList.includes(parentFieldLookup[props.filters.zones[i]])) {
          fieldList.push(parentFieldLookup[props.filters.zones[i]].toString());
        }
      }
      zoneInFilter = fieldList.includes(rowData.zoneId) || props.filters.zones.length == 0;
    }
    const equipmentInFilter =
      props.filters.equipment.includes(rowData.vehicleSN) || props.filters.equipment.length == 0;

    const taskInFilter = props.filters.tasks.includes(rowData.taskId) || props.filters.tasks.length == 0;

    const row = [
      rowData.zoneName,
      rowData.zoneTimeTotalFormatted,
      rowData.coverage,
      rowData.vehicleName,
      rowData.vehicleCoverage,
      customerSettings.cropview.vehicleAverageSpeedEnabled ? rowData.vehicleSpeed : '',
      rowData.taskName,
    ];
    const search = searchFind(<div>{JSON.stringify(row)}</div>, searchText.toLowerCase().trim());

    // If it doesn't pass the filter remove it from the totals for the zone
    if (!(zoneInFilter && equipmentInFilter && taskInFilter && search)) {
      tableDataList.forEach((tableDataListRow) => {
        if (tableDataListRow.zoneName == rowData.zoneName) {
          // We use the raw coverage value so that we don't accidentally subtract from the maxed area value
          // that sits on the coverageValue key, instead we overwrite it & check against applyAcreageCap
          if (applyAcreageCap) {
            tableDataListRow.zoneAcreTotal =
              Math.round((tableDataListRow.zoneAcreTotal - rowData.vehicleAcreTotal) * 100) / 100;
            tableDataListRow.zoneAcreTotalRaw =
              Math.round((tableDataListRow.zoneAcreTotalRaw - rowData.vehicleAcreTotal) * 100) / 100;
          } else {
            tableDataListRow.zoneAcreTotal =
              Math.round((tableDataListRow.zoneAcreTotal - rowData.vehicleAcreTotal) * 100) / 100;
          }

          tableDataListRow.coverage = `${Math.round(tableDataListRow.zoneAcreTotal * 100) / 100} ac `;
          // Check applyAcreageCap && against our new filtered value
          if (applyAcreageCap) {
            if (
              tableDataListRow.zoneAcreTotal > Math.round(tableDataListRow.zoneMaxAreaZone * 100) / 100 &&
              tableDataListRow.taskId == props.filters.tasks[0]
            ) {
              tableDataListRow.originalCoverage = tableDataListRow.zoneAcreTotal;
            }
            tableDataListRow.coverage = `${Math.min(
              tableDataListRow.zoneAcreTotal,
              Math.round(tableDataListRow.zoneMaxAreaZone * 100) / 100
            )} ac `;
          }

          tableDataListRow.zoneTimeTotal = tableDataListRow.zoneTimeTotal - rowData.vehicleTimeTotal;
          tableDataListRow.zoneTimeTotalFormatted = formatTime(tableDataListRow.zoneTimeTotal);
        }
      });
    } else if (applyAcreageCap && rowData.zoneAcreTotal > rowData.zoneMaxAreaZone) {
      rowData.coverage = `${Math.round(rowData.zoneMaxAreaZone * 100) / 100} ac `;
    }

    return zoneInFilter && equipmentInFilter && taskInFilter && search;
  }

  function taskSelectDropdown(rowData) {
    const taskConfigs = [];
    const taskConfigKeys = Object.keys(taskConfigIdDict);
    for (let i = 0; i < taskConfigKeys.length; i++) {
      taskConfigs.push(taskConfigIdDict[taskConfigKeys[i]]);
    }

    const sortedTaskDocs = taskConfigs.sort((a, b) => {
      return a.name == 'No Task' ? -1 : b.name == 'No Task' ? 1 : a.name.localeCompare(b.name);
    });

    // Add blank option
    sortedTaskDocs.unshift({
      taskId: '',
      name: '',
    });

    return (
      <select
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => {
          dispatch(
            updateEditCropviewValues({
              type: rowData.type,
              zoneName: rowData.zoneName,
              vehicleSN: rowData.vehicleSN,
              taskId: rowData.taskId,
              newTaskId: e.target.value,
              implementSN: rowData.implementSN,
            })
          );
        }}
        className='bg-light border rounded mx-1 coverage-table-task-select'
        defaultValue={rowData.taskId}
        disabled={rowData.taskPerm}
      >
        <option hidden value={''}></option>
        {sortedTaskDocs.map((taskDoc, index) => {
          if (!taskDoc.archived || taskDoc.taskId == rowData.taskId) {
            return (
              <option key={index} value={taskDoc.taskId}>
                {taskDoc.name}
              </option>
            );
          }
        })}
      </select>
    );
  }

  function recSelectDropdown(rowData) {
    const recIdList = [];
    recList.forEach((rec) => {
      recIdList.push(rec.id);
    });
    const sortedRecs = recIdList.sort((a, b) => {
      return a.localeCompare(b);
    });

    // Add blank option
    sortedRecs.unshift('');

    return (
      <select
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => {
          dispatch(
            updateEditCropviewRecValues({
              type: rowData.type,
              zoneName: rowData.zoneName,
              vehicleSN: rowData.vehicleSN,
              recId: e.target.value,
              entryTime: rowData.entryTime,
              exitTime: rowData.exitTime,
            })
          );
        }}
        className='bg-light border rounded mx-1 coverage-table-task-select'
        defaultValue={rowData.recId}
      >
        {sortedRecs.map((recId, index) => {
          return (
            <option key={index} value={recId}>
              {recId}
            </option>
          );
        })}
      </select>
    );
  }

  function zoomToZone(zone) {
    let delay = 0;
    // If map was hidden on small screen, delay the zoom until the map loads
    if (mapView == 0 && smallScreen) {
      delay = 10;
      dispatch(updateMapView(1));
    }
    setTimeout(() => {
      dispatch(updateZoneZoom(zone));
    }, delay);
    sendGAEvent('zoom_to_zone', displayedTable, 'cropview');
  }

  function zoomToVehicle(vehicleSN) {
    let delay = 0;
    // If map was hidden on small screen, delay the zoom until the map loads
    if (mapView == 0 && smallScreen) {
      delay = 10;
      dispatch(updateMapView(1));
    }
    setTimeout(() => {
      dispatch(updateVehicleZoom(vehicleSN));
    }, delay);
    sendGAEvent('zoom_to_vehicle', displayedTable, 'cropview');
  }

  function generateTableData() {
    if (tableData.length == 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={100} style={{textAlign: 'center', fontSize: '25px'}}>
              No activity for the selected date range
            </td>
          </tr>
        </tbody>
      );
    }

    let rowStartIdx = 0;
    let rowEndIdx = tableData.length;
    if (spans.length > 1) {
      if (page > 0) {
        const previousSpans = spans.slice(0, page * rowsPerPage);
        if (previousSpans.length > 0) {
          previousSpans.forEach((span) => {
            rowStartIdx += span;
          });
        }
      }
      const currentPageSpans = spans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      if (currentPageSpans.length > 0) {
        rowEndIdx = rowStartIdx;
        currentPageSpans.forEach((span) => {
          rowEndIdx += span;
        });
      }
    }

    if (props.type == 'equipment') {
      rowStartIdx = page * rowsPerPage;
      rowEndIdx = page * rowsPerPage + rowsPerPage;
      return (
        <tbody>
          {(rowsPerPage > 0 ? tableData.slice(rowStartIdx, rowEndIdx) : tableData).map((rowData, index) => {
            if (!rowData) {
              return null;
            }
            const row = (
              <tr
                key={index}
                onClick={() => {
                  if ($(window).width() <= 767) {
                    dispatch(setInitialValues(rowData));
                    $('#cropview-coverage-data-modal').modal('show');
                  }
                }}
              >
                <React.Fragment>
                  <td>
                    <div className='font-weight-bold'>{rowData.name}</div>
                  </td>
                  <td>
                    <div className='font-weight-normal'>{rowData.coverage}</div>
                  </td>
                  <td className={`${smallScreen && displayedColumn != 'timeInField' && 'd-none'}`}>
                    <div className='font-weight-normal'>{rowData.timeInFieldFormatted}</div>
                  </td>
                  <td className={`${smallScreen && displayedColumn != 'acreageRate' && 'd-none'}`}>
                    <div className='font-weight-normal'>{rowData.acreageRateFormatted}</div>
                  </td>
                  {customerSettings.general.outOfFieldTime && !(todayOnly && editCropview) && (
                    <td className={`${smallScreen && displayedColumn != 'timeOutField' && 'd-none'}`}>
                      <div className='font-weight-normal'>{rowData.timeOutFieldFormatted}</div>
                    </td>
                  )}
                  {customerSettings.general.idleTime && !(todayOnly && editCropview) && (
                    <td className={`${smallScreen && displayedColumn != 'timeIdle' && 'd-none'}`}>
                      <div className='font-weight-normal'>{rowData.timeIdleFormatted}</div>
                    </td>
                  )}
                  <td className={`${smallScreen && displayedColumn != 'timeTotal' && 'd-none'}`}>
                    <div className='font-weight-normal'>{rowData.timeTotalFormatted}</div>
                  </td>
                  {todayOnly && (
                    <td className={`${smallScreen && displayedColumn != 'task' && 'd-none'}`}>
                      {editCropview ? (
                        <div className='d-inline mx-1'>{taskSelectDropdown(rowData)}</div>
                      ) : (
                        <div className='font-weight-normal'>{rowData.taskName}</div>
                      )}
                    </td>
                  )}
                  {customerSettings.general.enableRecApplication && todayOnly && (
                    <td className={`${smallScreen && displayedColumn != 'rec' && 'd-none'}`}>
                      {editCropview ? (
                        <div className='d-inline mx-1'>{recSelectDropdown(rowData)}</div>
                      ) : (
                        <div className='font-weight-normal'>{rowData.recId}</div>
                      )}
                    </td>
                  )}
                  <td>
                    {
                      vehicleLatestLocationDict[rowData.vehicleSN] &&
                      vehicleLatestLocationDict[rowData.vehicleSN].latestGpsPoint.length > 0  && (
                      <button
                        type='button'
                        className='btn'
                        onClick={(e) => {
                          e.stopPropagation();
                          zoomToVehicle(rowData.vehicleSN);
                        }}
                      >
                        <FontAwesomeIcon icon='fas fa-map-marker-alt' />
                      </button>
                    )}
                  </td>
                </React.Fragment>
              </tr>
            );
            return row;
          })}
        </tbody>
      );
    }

    let colorFlip = false;
    return (
      <tbody>
        {(rowsPerPage > 0 ? tableData.slice(rowStartIdx, rowEndIdx) : tableData).map((rowData, index) => {
          if (!rowData) {
            return null;
          }
          if (rowData.span) {
            colorFlip = !colorFlip;
          }
          const row = (
            <tr
              key={index}
              style={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : 'rgba(255, 255, 255, 0)'}}
              onClick={() => {
                if ($(window).width() <= 767) {
                  dispatch(setInitialValues(rowData));
                  $('#cropview-coverage-data-modal').modal('show');
                }
              }}
            >
              {rowData.span && (
                <td rowSpan={rowData.span}>
                  <div className='font-weight-bold'>{rowData.zoneName}</div>
                </td>
              )}
              {rowData.span && (
                <td className={`${smallScreen && displayedColumn != 'coverage' && 'd-none'}`} rowSpan={rowData.span}>
                  <div className='font-weight-normal'>{rowData.zoneTimeTotalFormatted}</div>
                  <div className='font-weight-normal'>{rowData.coverage}</div>
                  {rowData.capApplied && (
                    <div>
                      <div style={{color: 'green', fontSize: 12}} className='font-weight-normal'>
                        {`Zone Completed`}
                      </div>
                      <div style={{color: 'green', fontSize: 12}} className='font-weight-normal'>
                        {`Acre Cap Applied`}
                      </div>
                    </div>
                  )}
                </td>
              )}
              <td className={`${smallScreen && displayedColumn != 'equipment' && 'd-none'}`}>
                <div className='font-weight-bold'>{rowData.vehicleName}</div>
                <div className='small'>{rowData.vehicleCoverage}</div>
              </td>
              {customerSettings.cropview.vehicleAverageSpeedEnabled && (
                <td className={`${smallScreen && displayedColumn != 'avgSpeed' && 'd-none'}`}>
                  <div className='font-weight-normal'>{rowData.vehicleSpeed}</div>
                </td>
              )}
              {(rowData.taskSpan || !smallScreen) && (
                <td
                  className={`${smallScreen && displayedColumn != 'task' && 'd-none'}`}
                  rowSpan={smallScreen ? rowData.taskSpan : undefined}
                >
                  {editCropview ? (
                    <div className='d-inline mx-1'>{taskSelectDropdown(rowData)}</div>
                  ) : (
                    <div className='font-weight-normal'>{rowData.taskName}</div>
                  )}
                </td>
              )}
              {customerSettings.general.enableRecApplication && (rowData.recSpan || !smallScreen) && (
                <td
                  className={`${smallScreen && displayedColumn != 'rec' && 'd-none'}`}
                  rowSpan={smallScreen ? rowData.recSpan : undefined}
                >
                  {editCropview ? (
                    <div className='d-inline mx-1'>{recSelectDropdown(rowData)}</div>
                  ) : (
                    <div className='font-weight-normal'>{rowData.recId}</div>
                  )}
                </td>
              )}
              {rowData.span && (
                <td rowSpan={rowData.span}>
                  <button
                    type='button'
                    className='btn'
                    onClick={(e) => {
                      e.stopPropagation();
                      zoomToZone(rowData.zoneName);
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-map-marker-alt' />
                  </button>
                </td>
              )}
            </tr>
          );
          return row;
        })}
      </tbody>
    );
  }

  function generateTableHeaders() {
    if (props.type == 'equipment') {
      return (
        <tr>
          <th width='20%'>
            <TableHeadFilterButtonTitleWrapper title={capitalizeFirstLetter(props.type)}>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#cropview-equipment-table-select').toggle();
                }}
                disabled={editCropview ? true : false}
              >
                <FontAwesomeIcon
                  icon='fas fa-filter'
                  style={{color: props.filters.equipment.length > 0 && '#4e73df'}}
                />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                key='cropview-equipment-table-select'
                id='cropview-equipment-table-select'
                name='equipment'
                multiple={true}
                search={true}
                value={props.filters.equipment}
                options={props.filterOptions.equipment}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </th>
          <React.Fragment>
            <th width='10%'>Field Acreage</th>
            <th className={`${smallScreen && displayedColumn != 'timeInField' && 'd-none'}`} width='15%'>
              In Field Time
            </th>
            <th className={`${smallScreen && displayedColumn != 'acreageRate' && 'd-none'}`} width='10%'>
              Acres/Hour
            </th>
            {customerSettings.general.outOfFieldTime && !(todayOnly && editCropview) && (
              <th className={`${smallScreen && displayedColumn != 'timeOutField' && 'd-none'}`} width='15%'>
                Out of Field Time
              </th>
            )}
            {customerSettings.general.idleTime && !(todayOnly && editCropview) && (
              <th className={`${smallScreen && displayedColumn != 'timeIdle' && 'd-none'}`} width='15%'>
                Idle Time
              </th>
            )}
            <th className={`${smallScreen && displayedColumn != 'timeTotal' && 'd-none'}`} width='15%'>
              Total Time
            </th>
            {todayOnly && (
              <th className={`${smallScreen && displayedColumn != 'task' && 'd-none'}`} width='15%'>
                Current Task
              </th>
            )}
            {customerSettings.general.enableRecApplication && todayOnly && (
              <th className={`${smallScreen && displayedColumn != 'rec' && 'd-none'}`} width='15%'>
                REC
              </th>
            )}
            <th className={`${!true && 'd-none'}`} width='5%'>
              <FontAwesomeIcon icon='fas fa-map-marker-alt' />
            </th>
          </React.Fragment>
        </tr>
      );
    }

    return (
      <tr>
        <th width='20%'>
          <TableHeadFilterButtonTitleWrapper title={capitalizeFirstLetter(props.type)}>
            <button
              className='btn btn-transparent btn-sm'
              onClick={() => {
                return tail.select('#cropview-zone-select').toggle();
              }}
              disabled={editCropview ? true : false}
            >
              <FontAwesomeIcon icon='fas fa-filter' style={{color: props.filters.zones.length > 0 && '#4e73df'}} />
            </button>
          </TableHeadFilterButtonTitleWrapper>
          <TableHeadHiddenDropdownWrapper>
            <Tailselect
              id='cropview-zone-select'
              name='zones'
              multiple={true}
              search={true}
              value={props.filters.zones}
              options={props.filterOptions.zones}
              onChange={handleFilters}
            />
          </TableHeadHiddenDropdownWrapper>
        </th>
        <th className={`${smallScreen && displayedColumn != 'coverage' && 'd-none'}`} width='15%'>
          Coverage
        </th>
        <th className={`${smallScreen && displayedColumn != 'equipment' && 'd-none'}`} width='25%'>
          <TableHeadFilterButtonTitleWrapper title='Equipment'>
            <button
              className='btn btn-transparent btn-sm'
              onClick={() => {
                return tail.select('#cropview-equipment-select').toggle();
              }}
              disabled={editCropview ? true : false}
            >
              <FontAwesomeIcon icon='fas fa-filter' style={{color: props.filters.equipment.length > 0 && '#4e73df'}} />
            </button>
          </TableHeadFilterButtonTitleWrapper>
          <TableHeadHiddenDropdownWrapper>
            <Tailselect
              id='cropview-equipment-select'
              name='equipment'
              multiple={true}
              search={true}
              value={props.filters.equipment}
              options={props.filterOptions.equipment}
              onChange={handleFilters}
            />
          </TableHeadHiddenDropdownWrapper>
        </th>
        {customerSettings.cropview.vehicleAverageSpeedEnabled && (
          <th className={`${smallScreen && displayedColumn != 'avgSpeed' && 'd-none'}`} width='10%'>
            Average Speed
          </th>
        )}
        <th className={`${smallScreen && displayedColumn != 'task' && 'd-none'}`} width='15%'>
          <TableHeadFilterButtonTitleWrapper title='Task'>
            <button
              className='btn btn-transparent btn-sm'
              onClick={() => {
                return tail.select('#cropview-task-select').toggle();
              }}
              disabled={editCropview ? true : false}
            >
              <FontAwesomeIcon icon='fas fa-filter' style={{color: props.filters.tasks.length > 0 && '#4e73df'}} />
            </button>
          </TableHeadFilterButtonTitleWrapper>
          <TableHeadHiddenDropdownWrapper>
            <Tailselect
              key='cropview-task-select'
              id='cropview-task-select'
              name='tasks'
              multiple={true}
              search={true}
              value={props.filters.tasks}
              options={props.filterOptions.tasks}
              onChange={handleFilters}
            />
          </TableHeadHiddenDropdownWrapper>
        </th>
        {customerSettings.general.enableRecApplication && (
          <th className={`${smallScreen && displayedColumn != 'rec' && 'd-none'}`} width='15%'>
            REC
          </th>
        )}
        <th width='5%'>
          <FontAwesomeIcon icon='fas fa-map-marker-alt' />
        </th>
      </tr>
    );
  }

  return (
    <div className={`table-responsive`} style={{width: smallScreen ? '100%' : props.dims.w + 'px'}}>
      <TabMenuTableWrapper
        table={<Table headers={generateTableHeaders()} body={generateTableData()} loading={props.loading} />}
        pagination={
          <CustomTablePagination
            count={props.type == 'equipment' ? tableData.length : spans.length}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        }
      />
    </div>
  );
}

export {CoverageTable};
